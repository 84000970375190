
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const SET_SESSION = 'SET_SESSION';
export const LOGOUT = 'LOGOUT';

export interface AuthState {
    session: any
    isLoggedIn: boolean
}

export interface Session {
    credentials: any,
    user: any
}