import React from "react";
import "./sites.css";
import AppHeader from "../../components/header/header";
import { connect } from "react-redux";
import { SitesState, Logo } from "../../store/sites/types";
import { AppState } from "../../store";
import {
  updateSiteField,
  updateLogo,
  loadSiteById,
  saveSite,
  removeSiteById,
  toogleCodeVisibility,
  emptySite,
} from "../../store/sites/actions";
import { withRouter } from "react-router";
import { HashLoader } from "react-spinners";
import "react-dropdown/style.css";
import Slider from "@mui/material/Slider";
import { loadCategories, loadAllOffers } from "../../store/offers/actions";
import Select from "react-select";
import StickySaveBar from "../../components/stickySaveBar/stickySaveBar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Highlight from "react-highlight";
import config from "../../config/app-config.json";
import { AccountsState } from "../../store/accounts/types";
import { SystemState } from "../../store/system/types";

const spinnerStyle = `
    margin: 100px auto;
`;

interface SiteEditInterfaceProps {
  sites: SitesState;
  accounts: AccountsState;
  system: SystemState;
  categories: Array<any>;
  allOffers: any;
  updateSiteField: Function;
  updateLogo: Function;
  loadSiteById: Function;
  saveSite: Function;
  removeSite: Function;
  loadCategories: Function;
  loadAllOffers: Function;
  emptySite: typeof emptySite;
  toogleCodeVisibility: typeof toogleCodeVisibility;
  match: {
    params: {
      id: number;
    };
  };
  history: any;
  logo: Logo;
}

class SiteEdit extends React.Component<SiteEditInterfaceProps> {
  public componentDidMount(): void {
    if (this.props.match.params.id) {
      this.props.loadSiteById(this.props.match.params.id);
    } else {
      this.props.emptySite();
    }
    this.props.loadCategories();
    this.props.loadAllOffers();
  }

  private handleChange(field: string, value: any): void {
    if (field === "excludeCategories") {
      if (value === null) {
        value = [];
      } else {
        value = value.map((val) => val.value);
      }
    }

    if (field === "excludeClients" || field === "excludeOffers") {
      if (value === null) {
        value = [];
      } else {
        value = value.map((val) => val.value);
      }
    }
    this.props.updateSiteField(field, value);
  }

  private fileChanged(event) {
    this.props.updateLogo(event.target.files[0]);
    console.log(this);
  }

  private handleSubmit(event): void {
    event.preventDefault();
    this.props.saveSite(
      this.props.sites.editedSite,
      this.props.history,
      this.props.logo.logo
    );
  }

  private remove(id: any): void {
    if (window.confirm("Are you sure you want to delete the Site?"))
      this.props.removeSite(id, this.props.history);
  }

  public showCode(): void {
    this.props.toogleCodeVisibility();
  }

  private getScript(): string {
    return `<div id="lm_plchldr"></div>
<script src="${config.adsUrl}/coreg.min.js?code=${this.props.sites.editedSite.code}"></script>`;
  }

  private getExtraButtons(): Array<any> {
    if (this.props.sites.editedSite.id) {
      return [
        {
          label: `${
            this.props.sites.editedSite.showCode ? "Hide" : "Show"
          } Code`,
          fn: this.showCode.bind(this),
        },
      ];
    }
    return [];
  }

  public render(): JSX.Element {
    console.log(this);
    console.log(this.props.sites.editedSite.smartHost == 1);
    return (
      <div>
        <AppHeader></AppHeader>
        <HashLoader
          //css={spinnerStyle}
          size={100}
          color={"#297DAC"}
          loading={this.props.sites.loadingSite}
        />
        {!this.props.sites.loadingSite ? (
          <form
            className="sections__form"
            onSubmit={this.handleSubmit.bind(this)}
          >
            <StickySaveBar extraButtons={this.getExtraButtons()} />

            {this.props.sites.editedSite.showCode ? (
              <section
                style={{
                  padding: "20px 20px 20px",
                }}
              >
                <div>
                  <Highlight language="html">{this.getScript()}</Highlight>

                  <CopyToClipboard text={this.getScript()}>
                    <button
                      className="copyToClipboard"
                      onClick={(event) => event.preventDefault()}
                    >
                      Copy to clipboard
                    </button>
                  </CopyToClipboard>
                </div>
              </section>
            ) : null}

            <section
              style={{
                padding: "20px 20px 20px",
              }}
            >
              <div className="page__title">
                <h1 className="section__name" style={{ padding: "0 0 10px 0" }}>
                  Site - {this.props.sites.editedSite.name}
                </h1>
                {this.props.sites.editedSite.id ? (
                  <a
                    href="#"
                    className="remove__icon"
                    onClick={() => this.remove(this.props.sites.editedSite.id)}
                  >
                    <img height="30" alt="" src="/assets/remove.png" />
                  </a>
                ) : null}
              </div>
              <div>
                <div className="flexBox">
                  <div>
                    <label>Name</label>
                    <div>
                      <input
                        required
                        type="text"
                        value={this.props.sites.editedSite.name}
                        onChange={(event) =>
                          this.handleChange("name", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label>Url Displayed On</label>
                    <div>
                      <input
                        required
                        type="text"
                        value={this.props.sites.editedSite.url}
                        onChange={(event) =>
                          this.handleChange("url", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label>Redirect Url</label>
                    <div>
                      <input
                        type="text"
                        value={this.props.sites.editedSite.redirectUrl}
                        onChange={(event) =>
                          this.handleChange("redirectUrl", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      className="checkbox"
                      style={{
                        marginLeft: 0,
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          this.handleChange(
                            "images",
                            !this.props.sites.editedSite.images
                          )
                        }
                        checked={this.props.sites.editedSite.images}
                      />
                      <span className="checkmark"></span>
                      <span
                        style={{
                          top: "5px",
                          position: "relative",
                        }}
                      >
                        Show image?
                      </span>
                    </label>
                  </div>

                  <div>
                    <label>Description</label>
                    <div>
                      <textarea
                        value={this.props.sites.editedSite.description}
                        onChange={(event) =>
                          this.handleChange("description", event.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>

                  <div>
                    <label>Exclude Categories</label>
                    <div className="select">
                      <Select
                        value={this.props.sites.editedSite.excludeCategories.map(
                          (value) => {
                            for (let cat of this.props.categories) {
                              if (cat.id == value) {
                                return {
                                  label: cat.name,
                                  value: value,
                                };
                              }
                            }
                          }
                        )}
                        onChange={(value) =>
                          this.handleChange("excludeCategories", value)
                        }
                        isMulti={true}
                        options={this.props.categories.map((cat) => {
                          return {
                            value: cat.id,
                            label: cat.name,
                          };
                        })}
                      />
                    </div>
                  </div>

                  {this.props.system.isAdmin &&
                  this.props.sites.editedSite.excludeClients ? (
                    <div>
                      <label>Exclude Clients</label>
                      <div className="select">
                        <Select
                          value={this.props.sites.editedSite.excludeClients.map(
                            (value) => {
                              for (let client of this.props.accounts.accounts) {
                                if (client.id === value) {
                                  return {
                                    label: client.name,
                                    value: value,
                                  };
                                }
                              }
                            }
                          )}
                          onChange={(value) =>
                            this.handleChange("excludeClients", value)
                          }
                          isMulti={true}
                          options={this.props.accounts.accounts.map((acc) => ({
                            label: acc.name,
                            value: acc.id,
                          }))}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.props.system.isAdmin &&
                  this.props.sites.editedSite.excludeOffers ? (
                    <div>
                      <label>Exclude Offers</label>
                      <div className="select">
                        <Select
                          value={this.props.sites?.editedSite?.excludeOffers?.map(
                            (value) => {
                              for (let offer of this.props.allOffers) {
                                if (offer.id === value) {
                                  return {
                                    label: offer.name,
                                    value: value,
                                  };
                                }
                              }
                            }
                          )}
                          onChange={(value) =>
                            this.handleChange("excludeOffers", value)
                          }
                          isMulti={true}
                          options={this.props.allOffers.map((offer) => ({
                            label: offer.name,
                            value: offer.id,
                          }))}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <label>Background Live Offer Tab</label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          this.handleChange(
                            "liveOfferBackground",
                            this.props.sites.editedSite.liveOfferBackground == 1
                              ? 0
                              : 1
                          )
                        }
                        checked={
                          this.props.sites.editedSite.liveOfferBackground == 1
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div>
                    <label>Live Offer</label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          this.handleChange(
                            "liveOffer",
                            this.props.sites.editedSite.liveOffer == 1
                              ? 0
                              : 1
                          )
                        }
                        checked={
                          this.props.sites.editedSite.liveOffer == 1
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{
                padding: "20px 20px 20px",
              }}
            >
              <div>
                <div className="page__title">
                  <h1
                    className="section__name"
                    style={{ padding: "0 0 10px 0" }}
                  >
                    Hosting
                  </h1>
                </div>
                <div style={{ margin: "15px" }}>
                  <div style={{ margin: "5px" }}>
                    <label
                      className="checkbox"
                      style={{
                        marginLeft: 0,
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          this.handleChange(
                            "smartHost",
                            this.props.sites.editedSite.smartHost == 1 ? 0 : 1
                          )
                        }
                        checked={this.props.sites.editedSite.smartHost == 1}
                      />
                      <span className="checkmark"></span>
                      <span
                        style={{
                          top: "5px",
                        }}
                      >
                        SmartBrands Hosting
                      </span>
                    </label>
                  </div>
                </div>
                {this.props.sites.editedSite.smartHost ? (
                  <div className="flexBox">
                    <div>
                      <label>Background Color</label>
                      <div>
                        <input
                          required
                          type="color"
                          value={this.props.sites.editedSite.backgroundColor}
                          onChange={(event) =>
                            this.handleChange(
                              "backgroundColor",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label>Text Color</label>
                      <div>
                        <input
                          required
                          type="color"
                          value={this.props.sites.editedSite.textColor}
                          onChange={(event) =>
                            this.handleChange("textColor", event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label>Our Hosted URL</label>
                      <div>
                        <input
                          type="text"
                          value={this.props.sites.editedSite.hostedSite}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Logo</label>
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) => this.fileChanged(event)}
                        />
                        {this.props.sites.editedSite.logo ? (
                          <img
                            src={this.props.sites.editedSite.logo}
                            alt="Your logo should be here looks like something is going wrong!"
                            width="100px"
                            height="100px"
                          />
                        ) : (
                          <>No logo exists</>
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Client Script</label>
                      <div>
                        <textarea
                          value={this.props.sites.editedSite.clientScript}
                          onChange={(event) =>
                            this.handleChange(
                              "clientScript",
                              event.target.value
                            )
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>

            <section
              style={{
                padding: "20px 20px 20px",
              }}
            >
              <div>
                <div className="page__title">
                  <h1
                    className="section__name"
                    style={{ padding: "0 0 10px 0" }}
                  >
                    Placeholders
                  </h1>
                </div>
                <div className="flexBox site__placeholders">
                  <div>
                    <label>Placeholder #1</label>
                    <div>
                      <input
                        type="text"
                        value={this.props.sites.editedSite.placeholder1}
                        onChange={(event) =>
                          this.handleChange("placeholder1", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label>Placeholder #2</label>
                    <div>
                      <input
                        type="text"
                        value={this.props.sites.editedSite.placeholder2}
                        onChange={(event) =>
                          this.handleChange("placeholder2", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label>Placeholder #3</label>
                    <div>
                      <input
                        type="text"
                        value={this.props.sites.editedSite.placeholder3}
                        onChange={(event) =>
                          this.handleChange("placeholder3", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label>Placeholder #4</label>
                    <div>
                      <input
                        type="text"
                        value={this.props.sites.editedSite.placeholder4}
                        onChange={(event) =>
                          this.handleChange("placeholder4", event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{
                padding: "20px 20px 20px",
              }}
            >
              <div className="page__title">
                <h1 className="section__name" style={{ padding: "0 0 10px 0" }}>
                  Settings
                </h1>
              </div>

              <div>
                <div className="flexBox">
                  <div>
                    <label>How many offers to show?</label>
                    <br /> <br />
                    <div>
                      <Slider
                        defaultValue={this.props.sites.editedSite.offers}
                        min={0}
                        max={20}
                        onChange={(event, value) =>
                          this.handleChange("offers", value)
                        }
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        valueLabelDisplay="on"
                      />
                    </div>
                  </div>

                  <div>
                    <label>How many pages to show?</label>
                    <br /> <br />
                    <div>
                      <Slider
                        defaultValue={this.props.sites.editedSite.pages}
                        min={0}
                        max={5}
                        onChange={(event, value) =>
                          this.handleChange("pages", value)
                        }
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        valueLabelDisplay="on"
                      />
                    </div>
                  </div>

                  <div>
                  <label
                      className="checkbox"
                      style={{
                        marginLeft: 0,
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          this.handleChange(
                            "coopAllowed",
                            this.props.sites.editedSite.coopAllowed == 1 ? 0 : 1
                          )
                        }
                        checked={this.props.sites.editedSite.coopAllowed == 1}
                      />
                      <span className="checkmark"></span>
                      <span
                        style={{
                          top: "5px",
                        }}
                      >
                        Coop Allowed?
                      </span>
                    </label>
                  </div>
                  {this.props.sites.editedSite.coopAllowed == 1 ? (
                  <div>
                    <label>Coop Count</label>
                    <br /> <br />
                    <div>
                      <Slider
                        defaultValue={this.props.sites.editedSite.coopCount}
                        min={1}
                        max={4}
                        onChange={(event, value) =>
                          this.handleChange("coopCount", value)
                        }
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        valueLabelDisplay="on"
                      />
                    </div>
                  </div>
                  ) : ''}
                </div>
              </div>
            </section>
          </form>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSiteField: (field, value) => dispatch(updateSiteField(field, value)),
    updateLogo: (logo) => dispatch(updateLogo(logo)),
    loadSiteById: (id) => dispatch(loadSiteById(id)),
    saveSite: (site, history, logo) => dispatch(saveSite(site, history, logo)),
    removeSite: (id, history) => dispatch(removeSiteById(id, history)),
    loadCategories: () => dispatch(loadCategories()),
    loadAllOffers: () => dispatch(loadAllOffers()),
    toogleCodeVisibility: () => dispatch(toogleCodeVisibility()),
    emptySite: () => dispatch(emptySite()),
  };
};

const mapStateToProps = (state: AppState) => ({
  sites: state.sites,
  categories: state.offers.categories,
  accounts: state.accounts,
  system: state.system,
  logo: state.logo,
  allOffers: state.offers.allOffers,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteEdit)
);
