import { LOAD_ACCOUNTS_SUCCESS, UPDATE_ACCOUNT_FIELD, LOAD_ACCOUNT_BY_ID, LOAD_ACCOUNT_BY_ID_SUCCESS, LOAD_ACCOUNTS, UPDATE_ACCOUNT_SUCCESS, Account, FILTER_ACCOUNTS } from "./types";
import axios from '../../lib/axios';
import config from '../../config/app-config.json';
import { selectInitialClient } from "../system/actions";


export const loadAccounts = (history = null, redirect = false) => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_ACCOUNTS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/clients`).then((response) => {
            dispatch({
                type: LOAD_ACCOUNTS_SUCCESS,
                payload: response.data.data
            })

            dispatch(selectInitialClient(response.data.data) );
            // if(history !== null && redirect) {
            //     history.push(`/account/${response.data.data[0].id}`);
            // }
        })
    }
}

export const updateAccountField = (field, value) => {
    return {
        type: UPDATE_ACCOUNT_FIELD,
        payload: {field, value}
    }
}

export const loadAccountById = id => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_ACCOUNT_BY_ID,
            payload: null
        })
        axios.get(`${config.serviceUrl}/clients/${id}`).then((response) => {
            dispatch({
                type: LOAD_ACCOUNT_BY_ID_SUCCESS,
                payload: response.data.data[0]
            })
        })
    }
}

export const saveAccount = (account: Account, history: any) => {
    return (dispatch: any) => {
        axios.put(`${config.serviceUrl}/clients/${account.id}`, account).then((response) => {
            history.push(`/`);
            history.push(`/account/${account.id}`);
            dispatch({
                type: UPDATE_ACCOUNT_SUCCESS,
                payload: response
            })
        })
    }
}

export const filterClients = (value: any) => ({
    type: FILTER_ACCOUNTS,
    payload: value
})