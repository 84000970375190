import { 
    LOAD_OFFERS_SUCCESS, 
    UPDATE_OFFER_FIELD, 
    LOAD_OFFER_BY_ID,
    LOAD_OFFER_BY_ID_SUCCESS,
    LOAD_OFFERS,
    LOAD_OFFERS_CAMPAIGN,
    LOAD_OFFERS_CAMPAIGN_SUCCESS,
    UPDATE_OFFER_SUCCESS,
    CREATE_OFFER_SUCCESS,
    Offer,
    REMOVE_OFFER_BY_ID,
    REMOVE_OFFER_BY_ID_SUCCESS,
    LOAD_CATEGORIES_SUCCESS,
    ADD_FIELD,
    REMOVE_FIELD,
    UPDATE_FIELD,
    SORT_OFFERS,
    REMOVE_IMAGE,
    LOAD_ALL_OFFERS,
    LOAD_ALL_OFFERS_SUCCESS,
} from "./types";
import axios from '../../lib/axios';
import config from '../../config/app-config.json';


export const loadOffers = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_OFFERS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/offers`).then((response) => {
            dispatch({
                type: LOAD_OFFERS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const loadOffersCampaigns = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_OFFERS_CAMPAIGN,
            payload: []
        })
        axios.get(`${config.serviceUrl}/offersForCampaign`).then((response) => {
            dispatch({
                type: LOAD_OFFERS_CAMPAIGN_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const loadAllOffers = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_ALL_OFFERS,
            payload: null
        })
        axios.get(`${config.serviceUrl}/allOffers/`).then((response) => {
            dispatch({
                type: LOAD_ALL_OFFERS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const sortOffers = col => {
    return dispatch => dispatch({
        type: SORT_OFFERS,
        payload: col
    })
}

export const updateOfferField = (field, value) => {
    return {
        type: UPDATE_OFFER_FIELD,
        payload: {field, value}
    }
}

export const loadOfferById = id => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_OFFER_BY_ID,
            payload: null
        })
        axios.get(`${config.serviceUrl}/offers/${id}`).then((response) => {
            dispatch({
                type: LOAD_OFFER_BY_ID_SUCCESS,
                payload: response.data.data[0]
            })
        })
    }
}

export const removeOfferById = (id, history) => {
    return (dispatch: any) => {
        dispatch({
            type: REMOVE_OFFER_BY_ID,
            payload: null
        })
        axios.delete(`${config.serviceUrl}/offers/${id}`).then((response) => {
            dispatch({
                type: REMOVE_OFFER_BY_ID_SUCCESS
            })

            history.push('/offers');
        })
    }
}

export const saveOffer = (offer: Offer, history: any) => {
    for(let field of offer.fields) {
        if(field.hardcodedValue !== null && field.hardcodedValue.length > 0) {
            field.required = false;
        }
    }
    return (dispatch: any) => {
        if(offer.id) {
            axios.put(`${config.serviceUrl}/offers/${offer.id}`, offer).then((response) => {
                history.push(`/`);
                history.push(`/offer/${offer.id}`);
                dispatch({
                    type: UPDATE_OFFER_SUCCESS,
                    payload: response
                })
            })
        } else {
            axios.post(`${config.serviceUrl}/offers`, offer).then((response) => {
                history.push(`/offer/${response.data.data.id}`);
                dispatch({
                    type: CREATE_OFFER_SUCCESS,
                    payload: response
                })

                history.push(`/offers`);
            })
        }
    }
}

export const loadCategories = () => {
    return (dispatch: any) => {
        axios.get(`${config.serviceUrl}/categories/`).then((response) => {
            dispatch({
                type: LOAD_CATEGORIES_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const addField = () => {
    return {
        type: ADD_FIELD
    }
}

export const removeField = index => {
    return {
        type: REMOVE_FIELD,
        payload: index
    }
}

export const updateField = (field, value, index) => {
    return {
        type: UPDATE_FIELD,
        payload: {
            field: field, 
            value: value,
            index: index
        }
    }
}

export const removeImage = () => {
    return {
        type: REMOVE_IMAGE
    }
}

export const emptyOffer = () => {
    return {
        type: CREATE_OFFER_SUCCESS,
        payload: null
    }
}