import React from 'react';
import './admin.css';
import './tabs.css'
import AppHeader from '../../../components/header/header';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { AppState } from '../../../store';
import { withRouter } from 'react-router';

import { HashLoader } from "react-spinners";
import { AdminReportsState } from '../../../store/reports/admin_reports/types';
import {
    loadAdminReports,
    loadAdminAdvertiserReports,
    loadAdminPublishersReports,
    sortAdminAdvertiserReports,
    sortAdminPublisherReports,
    filterByDate,
    filterByDateType,
    resetState,
} from '../../../store/reports/admin_reports/actions';
import { SystemState } from '../../../store/system/types';

import { RouteComponentProps } from 'react-router-dom';

import DataTable from '../../../components/dataTable';

const spinnerStyle = `
    margin: 100px auto;
`

const dateFilters = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 3 days', value: 'last 3 days' },
    { label: 'Last 7 days', value: 'last 7 days' },
    { label: 'Last 30 days', value: 'last 30 days' },
    { label: 'All Time', value: 'all Time' },
    { label: 'Custom', value: 'custom' }
];

const pbCols = [{
    fieldName: "Publisher's Name",
    fieldValue: "publishername",
    fieldType: 'string'
}, {
    fieldName: "Site Name",
    fieldValue: "sitename",
    fieldType: 'string'
}, {
    fieldName: "Site Visits",
    fieldValue: "site_visit_count",
    fieldType: 'int'
}, {
    fieldName: "Sign-Ups",
    fieldValue: "sign_up_count",
    fieldType: 'int'
}, {
    fieldName: "Non-Live Optins",
    fieldValue: "non_live_count",
    fieldType: 'int'
}, {
    fieldName: "Live-Optins",
    fieldValue: "live_count",
    fieldType: 'int'
}, {
    fieldName: "Optins Total",
    fieldValue: "total_count",
    fieldType: 'int'
}, {
    fieldName: "Non-Live Optins Revenue",
    fieldValue: "revenue_non_live",
    fieldType: 'int'
}, {
    fieldName: "Live Optins Revenue",
    fieldValue: "revenue_live",
    fieldType: 'int'
}, {
    fieldName: "Total Revenue",
    fieldValue: "revenue_total",
    fieldType: 'decimal'
}, {
    fieldName: "Sign-Up Percentage",
    fieldValue: "sign_up_percentage",
    fieldType: 'string'
}, {
    fieldName: "Optins Per Sign-Up",
    fieldValue: "optins_per_sign_up",
    fieldType: 'decimal'
}];

const adCols = [{
    fieldName: "Advertiser Name",
    fieldValue: "advertisername",
    fieldType: 'string'
}, {
    fieldName: "Offer Name",
    fieldValue: "offername",
    fieldType: 'string'
}, {
    fieldName: "Total Budget",
    fieldValue: "budget",
    fieldType: 'decimal'
}, {
    fieldName: "Impression Count",
    fieldValue: "impression_count",
    fieldType: 'int'
}, {
    fieldName: "Non-Live Optins",
    fieldValue: "non_live_count",
    fieldType: 'int'
}, {
    fieldName: "Live-Optins",
    fieldValue: "live_count",
    fieldType: 'int'
}, {
    fieldName: "Optins Total",
    fieldValue: "total_count",
    fieldType: 'int'
}, {
    fieldName: "Payout Per Lead",
    fieldValue: "payout_per_lead",
    fieldType: 'decimal'
}, {
    fieldName: "Non-Live Optins Payout",
    fieldValue: "payout_non_live",
    fieldType: 'decimal'
}, {
    fieldName: "Payout Per Live",
    fieldValue: "payout_per_live",
    fieldType: 'decimal'
}, {
    fieldName: "Live Optins Payout",
    fieldValue: "payout_live",
    fieldType: 'decimal'
}, {
    fieldName: "Total Payout",
    fieldValue: "payout_total",
    fieldType: 'decimal'
}, {
    fieldName: "Optin Percentage",
    fieldValue: "optin_percentage",
    fieldType: 'string'
}, {
    fieldName: "Budget Remaining",
    fieldValue: "budget_remaining",
    fieldType: 'decimal'
}, {
    fieldName: "Status",
    fieldValue: "status",
    fieldType: 'string'
}];

interface AdminReportsInterfaceProps {
    adminReports: AdminReportsState
    system: SystemState
    loadAdminReports: typeof loadAdminReports
    filterByDate: typeof filterByDate
    filterByDateType: typeof filterByDateType
    loadAdminPublishersReports: typeof loadAdminPublishersReports
    loadAdminAdvertiserReports: typeof loadAdminAdvertiserReports
    sortAdminAdvertiserReports: typeof sortAdminAdvertiserReports
    sortAdminPublisherReports: typeof sortAdminPublisherReports
    resetState: typeof resetState
}

class AdminReports extends React.Component<AdminReportsInterfaceProps & RouteComponentProps> {

    public componentDidMount(): void {
        const filter = { to: this.props.adminReports.filterByDate.to, from: this.props.adminReports.filterByDate.from }

        this.props.loadAdminReports(filter, this.props.adminReports.filterByDateType);
        this.props.resetState()
    }

    private loadReports(type: string, presetFilter: any = null): void {
        const filter = presetFilter == null ? this.props.adminReports.filterByDate : presetFilter;

        if (type === 'publisher') this.props.loadAdminPublishersReports(filter, this.props.adminReports.filterByDateType);
        if (type === 'advertiser') this.props.loadAdminAdvertiserReports(filter, this.props.adminReports.filterByDateType);
    }

    async handleChange(name: string, value: any) {
        console.log(name);
        console.log(value);
        if (name === 'filterDateType') {
            await this.props.filterByDateType(value);

            this.loadReports(this.props.adminReports.reportType === 1 ? 'publisher' : 'advertiser',);
        }
        if (name === 'filterDateTo') {
            console.log(value);
            console.log(new Date(value));
            const filter = { to: value, from: this.props.adminReports.filterByDate.from }
            this.props.filterByDate(filter);
        }
        if (name === 'filterDateFrom') {
            console.log(value);
            console.log(new Date(value));
            const filter = { to: this.props.adminReports.filterByDate.to, from: value }
            this.props.filterByDate(filter);
        }
    }

    public render(): JSX.Element {
        return (
            <div>
                <AppHeader></AppHeader>
                <div>
                    <button className={`${this.props.adminReports.reportType === 0 ? 'report-tab active' : 'report-tab'}`}
                        onClick={() => this.loadReports('advertiser')}
                        disabled={this.props.adminReports.reportType === 0}>Advertisers</button>
                    <button className={`${this.props.adminReports.reportType === 1 ? 'report-tab active' : 'report-tab'}`}
                        onClick={() => this.loadReports('publisher')}
                        disabled={this.props.adminReports.reportType === 1}>Publishers</button>

                    <CSVLink className='downloadbutton' filename={this.props.adminReports.reportType === 0 || this.props.adminReports.reportType === 1 ? "SmartCoreg" : "Smart-Recognition"} data={this.props.adminReports.reports}>
                        <span className='download'>
                            <svg className='download' focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                            </svg>
                            Export to CSV
                        </span>
                    </CSVLink>
                </div>
                <div>
                    Filter By Date
                    <Select
                        //label="Date"
                        value={this.props.adminReports.filterByDateType}
                        onChange={event =>
                            this.handleChange('filterDateType', event)
                        }
                        options={dateFilters}
                    />

                    {this.props.adminReports.filterByDateType.value === 'custom' ? (
                        <>
                            From
                            <DatePicker
                                label="From"
                                selected={this.props.adminReports.filterByDate.from}
                                onChange={event =>
                                    this.handleChange('filterDateFrom', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                            To
                            <DatePicker
                                label="To"
                                selected={this.props.adminReports.filterByDate.to}
                                onChange={event =>
                                    this.handleChange('filterDateTo', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                        </>
                    ) : null}
                    {!this.props.adminReports.loading ?
                        this.props.adminReports.reportType ?
                            <>
                                <DataTable orderField={this.props.adminReports.reportSortOptions.field} orderDirection={this.props.adminReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortAdminPublisherReports(col)} emptyText="No Reporting available yet" cols={pbCols} rows={this.props.adminReports.pbReports}></DataTable>
                                <table className="data__table">
                                    <tbody>
                                        <tr>
                                        <td>Total:</td>
                                            <td></td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.site_visit_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.sign_up_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.non_live_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.live_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.total_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.revenue_non_live).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.revenue_live).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.revenue_total).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{//this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                //return accumulator + Number(object.sign_up_percentage);
                                                //}, 0)
                                            }</td>
                                            <td>{//this.props.adminReports.pbReports.reduce((accumulator, object) => {
                                                //return accumulator + Number(object.optins_per_sign_up);
                                                //}, 0)
                                            }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </> :
                            <>
                                <DataTable orderField={this.props.adminReports.reportSortOptions.field} orderDirection={this.props.adminReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortAdminAdvertiserReports(col)} emptyText="No Reporting available yet" cols={adCols} rows={this.props.adminReports.adReports}></DataTable>
                                <table className="data__table">
                                    <tbody>
                                        <tr>
                                            <td>Total:</td>
                                            <td></td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.budget).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.impression_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.non_live_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.live_count);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return accumulator + Number(object.total_count);
                                            }, 0)}</td>
                                            <td>{//this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                //(Number(Number(accumulator).toFixed(2)) + Number(Number(object.payout_per_lead).toFixed(2))).toFixed(2);
                                                //}, 0)
                                            }</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.payout_non_live).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{//this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                //(Number(Number(accumulator).toFixed(2)) + Number(Number(object.payout_per_live).toFixed(2))).toFixed(2);
                                                //}, 0)
                                            }</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.payout_live).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.payout_total).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td>{//this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                //(Number(Number(accumulator).toFixed(2)) + Number(Number(object.optin_percentage).toFixed(2))).toFixed(2);
                                                //}, 0)
                                            }</td>
                                            <td>{this.props.adminReports.adReports.reduce((accumulator, object) => {
                                                return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.budget_remaining).toFixed(2))).toFixed(2);
                                            }, 0)}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </> :
                        <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.adminReports.loading} />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    adminReports: state.adminReports,
    system: state.system,
    loadAdminReports: loadAdminReports,
    loadAdminPublishersReports: loadAdminPublishersReports,
    loadAdminAdvertiserReports: loadAdminAdvertiserReports,
    filterByDateType: filterByDateType,
    filterByDate: filterByDate
});

export default withRouter(
    connect(mapStateToProps, {
        loadAdminReports,
        loadAdminPublishersReports,
        loadAdminAdvertiserReports,
        sortAdminAdvertiserReports,
        sortAdminPublisherReports,
        filterByDateType,
        filterByDate,
        resetState,
    })(AdminReports)
);
