import {
    PublisherReportsState,
    LOAD_REPORTS,
    LOAD_REPORTS_SUCCESS,
    PublisherReportsActionTypes,
    SORT_PUBLISHER_REPORTS,
    ADD_SITE,
    CHANGE_REPORT,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_PUB_STATE
  } from "./types";
import { sortByField } from "../../../lib/sort";
import { filterByDate } from "./actions";

  const initialSort = {
    field: 'timestamp',
    direction: false
}

  const initialState: PublisherReportsState = {
    pubreports: [],
    loading: false,
    reportSortOptions: initialSort,
    chosenSiteCodes: [],
    reportType: 4,
    filterByDate: {to: new Date(new Date(Date.now()).toISOString().split('T')[0]), from: new Date(new Date(Date.now()).toISOString().split('T')[0])},
    filterByDateType: {label: 'Last 7 days', value: 'Last 7 days'}
  };

  export function publisherReportsReducer(
    state = initialState,
    action: PublisherReportsActionTypes
  ): PublisherReportsState {
    console.log(action);
    switch (action.type) {
        case LOAD_REPORTS_SUCCESS:
          return {
              ...state,
              loading: false,
              pubreports: [...action.payload]
          };

        case LOAD_REPORTS:
          return {
              ...state,
              loading: true
          };

        case ADD_SITE:
          return {
              ...state,
              chosenSiteCodes: action.payload
          };

        case FILTER_DATE:
          console.log(action);
          console.log(state);
          return {
              ...state,
              filterByDate: action.payload
          };

          case FILTER_DATE_TYPE:
          return {
              ...state,
              filterByDateType: action.payload
          };

        case CHANGE_REPORT:
          return {
              ...state,
              reportType: action.payload
          };

        case SORT_PUBLISHER_REPORTS:
            state.reportSortOptions.direction = !state.reportSortOptions.direction;
            state.reportSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                pubreports: sortByField(state.reportSortOptions.field, state.reportSortOptions.direction, state.pubreports),
                reportSortOptions: Object.assign({}, state.reportSortOptions)
            }

        case RESET_PUB_STATE:
          return {
            pubreports: [],
            loading: false,
            reportSortOptions: initialSort,
            chosenSiteCodes: [],
            reportType: 4,
            filterByDate: {to: new Date(new Date(Date.now()).toISOString().split('T')[0]), from: new Date(new Date(Date.now()).toISOString().split('T')[0])},
            filterByDateType: {label: 'Last 7 days', value: 'Last 7 days'}
          }

      default:
        return state;
    }
  }
