import React from 'react';
import './publisher.css';
import '../../../components/dataTable/dataTable.css';
import AppHeader from '../../../components/header/header';
import DataTable from '../../../components/dataTable';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink, CSVDownload } from "react-csv";

import { connect } from "react-redux";
import { OffersState } from '../../../store/offers/types';
import { AppState } from '../../../store';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { HashLoader } from "react-spinners";
import { PublisherReportsState } from '../../../store/reports/publisher_reports/types';
import { loadPublisherReports, sortPublisherReports, loadPublisherSites, addSite, changeType, loadOffers, filterByDate, filterByDateType, resetPublisherState } from '../../../store/reports/publisher_reports/actions';
import { SystemState } from '../../../store/system/types';
import { SitesState } from '../../../store/sites/types';

const spinnerStyle = `
    margin: 100px auto;
`;

const dateFilters = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 3 days', value: 'last 3 days' },
    { label: 'Last 7 days', value: 'last 7 days' },
    { label: 'Last 30 days', value: 'last 30 days' },
    { label: 'All Time', value: 'all Time' },
    { label: 'Custom', value: 'custom' }
];

const optinCols = [{
    fieldName: "Email Address",
    fieldValue: "email",
    fieldType: 'string'
}, {
    fieldName: "Site",
    fieldValue: "sitename",
    fieldType: 'string'
}, {
    fieldName: "Offer",
    fieldValue: "offerid",
    fieldType: 'string'
}, {
    fieldName: "Timestamp",
    fieldValue: "timestamp",
    fieldType: 'datetime'
}, {
    fieldName: "Payout $",
    fieldValue: "payout",
    fieldType: 'double'
}];

const countCols = [{
    fieldName: "Site",
    fieldValue: "sitename",
    fieldType: 'string'
}, {
    fieldName: "Sign-Ups",
    fieldValue: "submission_count",
    fieldType: 'int'
}, {
    fieldName: "Site Visits",
    fieldValue: "impression_count",
    fieldType: 'int'
}, {
    fieldName: "Optins",
    fieldValue: "optin_count",
    fieldType: 'int'
}, {
    fieldName: "Revenue",
    fieldValue: "revenue",
    fieldType: 'string'
}];

const recogCols = [{
    fieldName: "URL",
    fieldValue: "fullurl",
    fieldType: 'string'
}, {
    fieldName: "Email Address",
    fieldValue: "email",
    fieldType: 'string'
}, {
    fieldName: "Timestamp",
    fieldValue: "timestamp",
    fieldType: 'datetime'
}, {
    fieldName: "IP Address",
    fieldValue: "ip",
    fieldType: 'string'
}];

interface PublisherReportsInterfaceProps {
    publisherReports: PublisherReportsState
    system: SystemState
    sites: SitesState
    offers: OffersState
    loadOffers: typeof loadOffers
    loadPublisherSites: typeof loadPublisherSites
    loadPublisherReports: typeof loadPublisherReports
    sortPublisherReports: typeof sortPublisherReports
    addSite: typeof addSite
    changeType: typeof changeType
    filterByDate: typeof filterByDate
    filterByDateType: typeof filterByDateType
    resetPublisherState: typeof resetPublisherState
}

class PublisherReports extends React.Component<PublisherReportsInterfaceProps & RouteComponentProps> {

    public componentDidMount(): void {
        //this.loadPage();
        this.props.loadPublisherSites();
        this.props.loadOffers();
        this.props.resetPublisherState();
    }

    private async loadReports(type, dateType, sitesArray) {

        const sites = this.props.publisherReports.chosenSiteCodes.length == 0 ? String(this.props.sites.sites.map(site => { return site.code })) : String(this.props.publisherReports.chosenSiteCodes);
        console.log(sites);

        this.props.loadPublisherReports(
            type == null ? this.props.publisherReports.reportType : type,
            sitesArray == null ? sites : String(sitesArray),
            this.props.publisherReports.filterByDate,
            dateType == null ? this.props.publisherReports.filterByDateType : dateType,
            this.props.system.selectedClient?.leadStatusReporting,
            this.props.system.selectedClient?.smartRecognition == 1 ? this.props.system.selectedClient.id : null
        );
    }


    handleChange(name: string, value: any) {
        console.log(name);
        console.log(value);
        if (name == 'chosenSiteCodes') {
            if (value === null) {
                value = [];
            } else {
                value = value.map(val => val.value);
                //const array = this.props.publisherReports.chosenSiteCodes;
                //array.push(value.value);
                this.props.addSite(value);
                this.loadReports(this.props.publisherReports.reportType == 4 ? 0 : null, null, value);
            }
        }
        if (name == 'changeType') {
            this.changeColor(value == 0 ? 'opt' : value == 1 ? 'cnt' : 'rcg');
            this.props.changeType(value);
            this.loadReports(value, null, null);
        }
        if (name == 'filterDateType') {
            console.log(value);
            this.props.filterByDateType(value);
            this.loadReports(this.props.publisherReports.reportType == 4 ? 0 : null, value, null);
        }
        if (name == 'filterDateTo') {
            console.log(value);
            console.log(new Date(value));
            const filter = { to: value, from: this.props.publisherReports.filterByDate.from }
            this.props.filterByDate(filter);
        }
        if (name == 'filterDateFrom') {
            console.log(value);
            console.log(new Date(value));
            const filter = { to: this.props.publisherReports.filterByDate.to, from: value }
            this.props.filterByDate(filter);
        }
    }

    changeColor(val: string) {
        document!.getElementById("opt")!.style.backgroundColor = "#297dac";
        document!.getElementById("opt")!.style.color = "white";
        document!.getElementById("opt")!.style.border = "0";
        document!.getElementById("cnt")!.style.backgroundColor = "#297dac";
        document!.getElementById("cnt")!.style.color = "white";
        document!.getElementById("cnt")!.style.border = "0";
        if (this.props.system!.selectedClient!.smartRecognition! == 1) {
            document!.getElementById("rcg")!.style.backgroundColor = "#297dac";
            document!.getElementById("rcg")!.style.color = "white";
            document!.getElementById("rcg")!.style.border = "0";
        }
        document!.getElementById(val)!.style.backgroundColor = "white";
        document!.getElementById(val)!.style.color = "black";
        document!.getElementById(val)!.style.border = "solid 1px #297dac";
    }

    public render(): JSX.Element {
        console.log(this);
        //var optinReports: any = [];
        //var countReports: any = [];
        //var recogReports: any = [];
        //var optinReports2: any = [];
        /*
        if (this.props.publisherReports.loading == false) {
            if (this.props.publisherReports.reportType == 0 && this.props.publisherReports.pubreports.map(opt => {return opt.livepayout})[0] != undefined) {
                optinReports = this.props.publisherReports.pubreports.map(({ percenttopublisher, livepayout, ...object }) => {
                    return {
                        ...object, sitename: this.props.sites.sites.find(site => site.code == object.sitecode)?.name,
                        payout: livepayout == this.props.publisherReports.pubreports.find(optin => optin.email == object.email && optin.timestamp == object.timestamp && optin.livepayout == Math.max(...this.props.publisherReports.pubreports.filter(item => item.timestamp == object.timestamp && item.email == object.email).map(opt => { return opt.livepayout }))).livepayout ? ((Number(object.payout) + Number(livepayout)) * Number(percenttopublisher)).toFixed(2) : (Number(object.payout) * Number(percenttopublisher)).toFixed(2),
                        timestamp: new Date(object.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })
                    };
                });
                optinReports2 = optinReports.filter((e, i) => {
                    return optinReports.findIndex((x) => {
                    return x.email == e.email && x.offerid == e.offerid;}) == i;
                });
                console.log(optinReports2);
                //this.changeColor("opt");
            } else if (this.props.publisherReports.reportType == 1) {
                countReports = this.props.publisherReports.pubreports.map(object => {
                    return { ...object, sitename: this.props.sites.sites.find(site => site.code == object.code)?.name, revenue: Number(object.revenue).toFixed(2) };
                });
                //this.changeColor("cnt");
            } else if (this.props.publisherReports.reportType == 2) {
                recogReports = this.props.publisherReports.pubreports;
                //this.changeColor("rcg");
            }
        }
        */
        console.log(this.props.publisherReports.pubreports);

        return (
            <div>


                <AppHeader></AppHeader>
                <div>
                    <button className='tabs' id="opt" onClick={() => this.handleChange('changeType', 0)}>Optins</button>

                    <button className='tabs' id="cnt" onClick={() => this.handleChange('changeType', 1)}>Counts</button>

                    {this.props.system.selectedClient!.smartRecognition == 1 ? <button className='tabs' id="rcg" onClick={() => this.handleChange('changeType', 2)}>Recognition</button> : null}

                    <CSVLink className='downloadbutton' filename={this.props.publisherReports.reportType == 0 || this.props.publisherReports.reportType == 1 ? "SmartCoreg" : "Smart-Recognition"} data={this.props.publisherReports.pubreports}>
                        <span className='download'>
                            <svg className='download' focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                            </svg>
                            Export to CSV
                        </span>
                    </CSVLink>
                </div>

                <div>
                    Filter By Date
                    <Select
                        //label="Date"
                        value={this.props.publisherReports.filterByDateType}
                        onChange={event =>
                            this.handleChange('filterDateType', event)
                        }
                        options={dateFilters}
                    />

                    {this.props.publisherReports.filterByDateType.value === 'custom' ? (
                        <>
                            From
                            <DatePicker
                                label="From"
                                selected={this.props.publisherReports.filterByDate.from}
                                onChange={event =>
                                    this.handleChange('filterDateFrom', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                            To
                            <DatePicker
                                label="To"
                                selected={this.props.publisherReports.filterByDate.to}
                                onChange={event =>
                                    this.handleChange('filterDateTo', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                        </>
                    ) : null}
                </div>
                {this.props.publisherReports.reportType == 0 ?
                    <>
                        <div>
                            Filter By Site
                            <Select
                                value={this.props.publisherReports.chosenSiteCodes.map(value => {
                                    console.log(value);
                                    for (let site of this.props.sites.sites) {
                                        console.log(site);
                                        if (site.code == value) {
                                            return {
                                                label: site.name,
                                                value: value
                                            }
                                        }
                                    }
                                })}
                                onChange={(value) => this.handleChange('chosenSiteCodes', value)}
                                isMulti={true}
                                options={this.props.sites.sites.map(site => {
                                    return {
                                        value: site.code,
                                        label: site.name
                                    }
                                })}
                            />
                        </div>
                        <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.publisherReports.loading} />
                        {!this.props.publisherReports.loading ?
                            <div>
                                Optins
                                <DataTable orderField={this.props.publisherReports.reportSortOptions.field} orderDirection={this.props.publisherReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortPublisherReports(col)} emptyText="No Reporting available yet" cols={optinCols} rows={this.props.publisherReports.pubreports}></DataTable>
                            </div>
                            : 'LOADING'}
                    </> : this.props.publisherReports.reportType == 1 ?
                        <>
                            <div>
                                Filter By Site
                                <Select
                                    value={this.props.publisherReports.chosenSiteCodes.map(value => {
                                        console.log(value);
                                        for (let site of this.props.sites.sites) {
                                            console.log(site);
                                            if (site.code == value) {
                                                return {
                                                    label: site.name,
                                                    value: value
                                                }
                                            }
                                        }
                                    })}
                                    onChange={(value) => this.handleChange('chosenSiteCodes', value)}
                                    isMulti={true}
                                    options={this.props.sites.sites.map(site => {
                                        return {
                                            value: site.code,
                                            label: site.name
                                        }
                                    })}
                                />
                            </div>
                            <HashLoader
                                //css={spinnerStyle}
                                size={100}
                                color={"#297DAC"}
                                loading={this.props.publisherReports.loading} />
                            {!this.props.publisherReports.loading ?
                                <div>
                                    Optins
                                    <DataTable orderField={this.props.publisherReports.reportSortOptions.field} orderDirection={this.props.publisherReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortPublisherReports(col)} emptyText="No Reporting available yet" cols={countCols} rows={this.props.publisherReports.pubreports}></DataTable>
                                    <table className="data__table">
                                    <tbody>
                                            <tr>
                                                <td>Total:</td>
                                                <td>{this.props.publisherReports.pubreports.reduce((accumulator, object) => {
                                                    return accumulator + Number(object.submission_count);
                                                }, 0)}</td>
                                                <td>{this.props.publisherReports.pubreports.reduce((accumulator, object) => {
                                                    return accumulator + Number(object.impression_count);
                                                }, 0)}</td>
                                                <td>{this.props.publisherReports.pubreports.reduce((accumulator, object) => {
                                                    return accumulator + Number(object.optin_count);
                                                }, 0)}</td>
                                                <td>{this.props.publisherReports.pubreports.reduce((accumulator, object) => {
                                                    return (Number(Number(accumulator).toFixed(2)) + Number(Number(object.revenue).toFixed(2))).toFixed(2);
                                                }, 0)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                : 'LOADING'}
                        </> : this.props.publisherReports.reportType == 2 ? <>
                            <div>
                                Filter By Site
                                <Select
                                    value={this.props.publisherReports.chosenSiteCodes.map(value => {
                                        console.log(value);
                                        for (let site of this.props.sites.sites) {
                                            console.log(site);
                                            if (site.code == value) {
                                                return {
                                                    label: site.name,
                                                    value: value
                                                }
                                            }
                                        }
                                    })}
                                    onChange={(value) => this.handleChange('chosenSiteCodes', value)}
                                    isMulti={true}
                                    options={this.props.sites.sites.map(site => {
                                        return {
                                            value: site.code,
                                            label: site.name
                                        }
                                    })}
                                />
                            </div>
                            <HashLoader
                                //css={spinnerStyle}
                                size={100}
                                color={"#297DAC"}
                                loading={this.props.publisherReports.loading} />
                            {!this.props.publisherReports.loading ?
                                <div>
                                    Optins
                                    <DataTable orderField={this.props.publisherReports.reportSortOptions.field} orderDirection={this.props.publisherReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortPublisherReports(col)} emptyText="No Reporting available yet" cols={recogCols} rows={this.props.publisherReports.pubreports}></DataTable>
                                </div>
                                : 'LOADING'}
                        </> : null
                }


            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    publisherReports: state.publisherReports,
    sites: state.sites,
    system: state.system,
    offers: state.offers,
    loadPublisherReports: loadPublisherReports,
    sortPublisherReports: sortPublisherReports,
    loadPublisherSites: loadPublisherSites,
    addSite: addSite,
    changeType: changeType,
    loadOffers: loadOffers,
    filterByDate: filterByDate,
    filterByDateType: filterByDateType,
    resetPublisherState: resetPublisherState
});

export default withRouter(
    connect(mapStateToProps, {
        loadPublisherReports,
        sortPublisherReports,
        loadPublisherSites,
        addSite,
        changeType,
        loadOffers,
        filterByDate,
        filterByDateType,
        resetPublisherState
    })(PublisherReports)
);
