import React from 'react';
import './stickySaveBar.css';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/actions';

interface StickySaveBarInterface {
    extraButtons?: Array<any>
}

class StickySaveBar extends React.Component<StickySaveBarInterface> {
    componentDidMount() {
    }

    render() {
        return (
            <div className="sticky__save-header">
                <button className="button accent">Save</button>
                {this.props.extraButtons && this.props.extraButtons.map((btn, i) => 
                    <button key={`sticky_btn_${i}`}className="button accent" onClick={(event) => {event.preventDefault(); btn.fn()}}>{btn.label}</button>
                )}
            </div>
        )
    }

}

export default StickySaveBar;