import { LOAD_SITES_SUCCESS, UPDATE_SITE_FIELD, UPDATE_LOGO, LOAD_SITE_BY_ID, LOAD_SITE_BY_ID_SUCCESS, LOAD_SITES, UPDATE_SITE_SUCCESS, CREATE_SITE_SUCCESS, Site, REMOVE_SITE_BY_ID, REMOVE_SITE_BY_ID_SUCCESS, SORT_SITES } from "./types";
import axios from '../../lib/axios';
import config from '../../config/app-config.json';
import { TOGGLE_CODE_VISIBILITY } from "../offers/types";


export const loadSites = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_SITES,
            payload: []
        })
        axios.get(`${config.serviceUrl}/sites`).then((response) => {
            dispatch({
                type: LOAD_SITES_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const sortSites = col => {
    return dispatch => dispatch({
        type: SORT_SITES,
        payload: col
    })
}

export const updateSiteField = (field, value) => {
    return {
        type: UPDATE_SITE_FIELD,
        payload: { field, value }
    }
}

export const updateLogo = (logo) => {
    return {
        type: UPDATE_LOGO,
        payload: logo
    }
}

export const loadSiteById = id => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_SITE_BY_ID,
            payload: null
        })
        axios.get(`${config.serviceUrl}/sites/${id}`).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_SITE_BY_ID_SUCCESS,
                payload: response.data.data[0]
            })
        })
    }
}

export const removeSiteById = (id, history) => {
    return (dispatch: any) => {
        dispatch({
            type: REMOVE_SITE_BY_ID,
            payload: null
        })
        axios.delete(`${config.serviceUrl}/sites/${id}`).then((response) => {
            dispatch({
                type: REMOVE_SITE_BY_ID_SUCCESS
            })

            history.push('/sites');
        })
    }
}

export const saveSite = (site: Site, history: any, logo: any) => {
    function saveLogo(name: any, logo: any) {

        console.log(name);
        console.log(logo);
        axios.get(`${config.serviceUrl}/presignedurl/${name}`).then((response) => {
            console.log(response);
            const res = JSON.parse(response.data.data.body);
            console.log(res);
            const myHeaders = new Headers({ 'Content-Type': 'image/*' });
            const fet = fetch(res.presigned_url, {
                method: 'PUT',
                headers: myHeaders,
                body: logo
            }).then(nex => {
                console.log(nex);
            });

        });
    }
    console.log(site);
    console.log(logo);
    return (dispatch: any) => {
        if (site.id) {
            axios.put(`${config.serviceUrl}/sites/${site.id}`, site).then((response) => {
                if (logo != null) {
                    saveLogo(site.id, logo);
                }
                history.push(`/`);
                history.push(`/site/${site.id}`);
                dispatch({
                    type: UPDATE_SITE_SUCCESS,
                    payload: response
                })
            })
        } else {
            axios.post(`${config.serviceUrl}/sites`, site).then((response) => {
                if (logo != null) {
                    saveLogo(response.data.data.id, logo);
                }
                history.push(`/site/${response.data.data.id}`);
                dispatch({
                    type: CREATE_SITE_SUCCESS,
                    payload: response
                })

                dispatch(loadSiteById(response.data.data.id));
            })
        }
    }
}



export const toogleCodeVisibility = () => {
    return {
        type: TOGGLE_CODE_VISIBILITY
    }
}

export const emptySite = () => {
    return {
        type: CREATE_SITE_SUCCESS,
        payload: null
    }
}
