import { TOGGLE_CODE_VISIBILITY } from "../offers/types";


export const LOAD_SITES = "LOAD_SITES";
export const LOAD_SITES_SUCCESS = "LOAD_SITES_SUCCESS";
export const UPDATE_SITE_FIELD = "UPDATE_SITE_FIELD";
export const LOAD_SITE_BY_ID = "LOAD_SITE_BY_ID";
export const LOAD_SITE_BY_ID_SUCCESS = "LOAD_SITE_BY_ID_SUCCESS";
export const CREATE_SITE_SUCCESS = "CREATE_SITE_SUCCESS";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const REMOVE_SITE_BY_ID = "REMOVE_SITE_BY_ID";
export const REMOVE_SITE_BY_ID_SUCCESS = "REMOVE_SITE_BY_ID_SUCCESS";
export const SORT_SITES = "SORT_SITES";
export const UPDATE_LOGO = "UPDATE_LOGO";

export interface SitesState {
    sites: Array<Site>
    editedSite: Site
    loading: boolean
    loadingSite: boolean
    sitesSortOptions: {
        field: string
        direction: boolean
    }
}

export interface Logo {
    logo: any
}

export interface Site {
    excludeOffers: any;
    id?: number
    name: string
    url: string
    description: string
    code?: string
    placeholder1: string
    placeholder2: string
    placeholder3: string
    placeholder4: string
    offers: number
    pages: number
    smartHost: number
    hostedSite: string
    logo: string
    clientScript: string
    backgroundColor: string
    textColor: string
    excludeCategories: Array<number>
    redirectUrl: string
    images: boolean
    showCode?: boolean
    excludeClients?: Array<number>
    liveOfferBackground: number
    liveOffer: number
    coopAllowed: number
    coopCount: number
}

export interface Category {
    id: number
    name: string
}


interface LoadSites {
    type: typeof LOAD_SITES_SUCCESS | typeof LOAD_SITES,
    payload: Array<Site>
}

interface LoadSiteById {
    type: typeof LOAD_SITE_BY_ID_SUCCESS | typeof LOAD_SITE_BY_ID,
    payload: Site
}

interface UpdateSiteField {
    type: typeof UPDATE_SITE_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateLogo {
    type: typeof UPDATE_LOGO,
    payload: any
}

interface UpdateSite {
    type: typeof UPDATE_SITE_SUCCESS,
    payload: any
}

interface CreateSite {
    type: typeof CREATE_SITE_SUCCESS,
    payload: any
}

interface RemoveSite {
    type: typeof REMOVE_SITE_BY_ID_SUCCESS,
    payload: any
}

interface SortSites {
    type: typeof SORT_SITES,
    payload: any
}

interface ToogleCodeVisibility {
    type: typeof TOGGLE_CODE_VISIBILITY
}

export type SiteActionTypes =
    LoadSites |
    UpdateSiteField |
    LoadSiteById |
    LoadSites |
    CreateSite |
    UpdateSite |
    RemoveSite |
    SortSites |
    ToogleCodeVisibility;

export type LogoActionTypes = UpdateLogo;