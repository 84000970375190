

export const LOAD_OFFERS = "LOAD_OFFERS";
export const LOAD_OFFERS_SUCCESS = "LOAD_OFFERS_SUCCESS";
export const LOAD_ALL_OFFERS = "LOAD_ALL_OFFERS";
export const LOAD_ALL_OFFERS_SUCCESS = "LOAD_ALL_OFFERS_SUCCESS";
export const UPDATE_OFFER_FIELD = "UPDATE_OFFER_FIELD";
export const LOAD_OFFER_BY_ID = "LOAD_OFFER_BY_ID";
export const LOAD_OFFER_BY_ID_SUCCESS = "LOAD_OFFER_BY_ID_SUCCESS";
export const LOAD_OFFERS_CAMPAIGN = "LOAD_OFFERS_CAMPAIGN";
export const LOAD_OFFERS_CAMPAIGN_SUCCESS = "LOAD_OFFERS_CAMPAIGN_SUCCESS";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const REMOVE_OFFER_BY_ID = "REMOVE_OFFER_BY_ID";
export const REMOVE_OFFER_BY_ID_SUCCESS = "REMOVE_OFFER_BY_ID_SUCCESS";
export const LOAD_CATEGORIES_SUCCESS = "LOAD_CATEGORIES_SUCCESS";
export const ADD_FIELD = "ADD_FIELD";
export const REMOVE_FIELD = "REMOVE_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const SORT_OFFERS = "SORT_OFFERS";
export const TOGGLE_CODE_VISIBILITY = "TOGGLE_CODE_VISIBILITY";
export const REMOVE_IMAGE = "REMOVE_IMAGE";

export interface OffersState {
    offers: Array<Offer>
    editedOffer: Offer
    loading: boolean
    loadingOffer: boolean
    categories: Array<Category>
    offersSortOptions: {
        field: string
        direction: boolean
    }
    allOffers: any
    availableOffers: any
}

export interface Field {
    required: boolean
    name: string
    rename?: string
    hardcodedValue?: any
}

export interface Offer {
    name: string;
    uuid?: string
    id?: number
    clientId?: number
    categoryId: number | string
    title: string
    offeredBy: string
    description: string
    image?: string | null
    payoutLead: number
    percentToPublisher: number
    webhookUrl: string
    webhookMethod: string
    dailyLeadCap: number
    totalLeadAmount: number
    totalBudget: number
    preCheck: boolean
    uniquesOnly: boolean
    mobileDesktop: string
    usaOnly: boolean
    excludeStates: Array<string>,
    fields: Array<Field>
    liveUrl: string
    livePayout: number
    liveCap: number
    enabled: boolean
    privacyPolicyUrl: string,
    header: string,
    excludeClients?: Array<number>,
    sites?: Array<number>,
    internal?: boolean,
    offerType: string
    coopPayout: number
}

export interface Category {
    id: number
    name: string
}


interface LoadOffers {
    type: typeof LOAD_OFFERS_SUCCESS | typeof LOAD_OFFERS
    payload: Array<Offer>
}

interface LoadAllOffers {
    type: typeof LOAD_ALL_OFFERS_SUCCESS | typeof LOAD_ALL_OFFERS
    payload: any
}

interface LoadOfferById {
    type: typeof LOAD_OFFER_BY_ID_SUCCESS | typeof LOAD_OFFER_BY_ID
    payload: Offer
}

interface LoadOffersCampaigns {
    type: typeof LOAD_OFFERS_CAMPAIGN_SUCCESS | typeof LOAD_OFFERS_CAMPAIGN
    payload: Array<Offer>
}

interface LoadOfferById {
    type: typeof LOAD_OFFER_BY_ID_SUCCESS | typeof LOAD_OFFER_BY_ID
    payload: Offer
}

interface UpdateOfferField {
    type: typeof UPDATE_OFFER_FIELD
    payload: {
        field: string
        value: string
    }
}

interface UpdateOffer {
    type: typeof UPDATE_OFFER_SUCCESS
    payload: any
}

interface CreateOffer {
    type: typeof CREATE_OFFER_SUCCESS
    payload: any
}

interface RemoveOffer {
    type: typeof REMOVE_OFFER_BY_ID_SUCCESS
    payload: any
}

interface LoadCategories {
    type: typeof LOAD_CATEGORIES_SUCCESS
    payload: Array<Category>
}

interface AddField {
    type: typeof ADD_FIELD
}

interface RemoveField {
    type: typeof REMOVE_FIELD
    payload: number
}

interface UpdateField {
    type: typeof UPDATE_FIELD
    payload: {
        field: string
        value: string | number
        index: number
    }
}

interface SortOffers {
    type: typeof SORT_OFFERS,
    payload: any
}

interface RemoveImage {
    type: typeof REMOVE_IMAGE
}

export type OfferActionTypes = 
    LoadOffers | 
    UpdateOfferField | 
    LoadOfferById | 
    LoadOffers | 
    LoadOffersCampaigns |
    CreateOffer | 
    UpdateOffer | 
    RemoveOffer | 
    LoadCategories | 
    AddField | 
    RemoveField | 
    UpdateField | 
    SortOffers | 
    RemoveImage |
    LoadAllOffers
