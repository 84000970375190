import cognitoUtils from '../../lib/cognitoUtils'
import { CLEAR_SESSION, SET_SESSION, LOGOUT } from './types'
import { CHECK_ADMIN_RIGHTS } from '../system/types'

export const clearSession = () => ({
  type: CLEAR_SESSION
})

// Initialise the Cognito sesson from a callback href
export function initSessionFromCallbackURI (callbackHref: string) {
  return function (dispatch: any) {
    return cognitoUtils.parseCognitoWebResponse(callbackHref) // parse the callback URL
      .then(() => cognitoUtils.getCognitoSession()) // get a new session
      .then((session: any) => {
        dispatch({ type: SET_SESSION, session });
        dispatch({ type: CHECK_ADMIN_RIGHTS });
      })
  }
}

export const setSession = (session: any) => ({
  type: SET_SESSION,
  session
})

export const logout = () => ({
  type: LOGOUT
})