export const LOAD_AD_REPORTS = "LOAD_AD_REPORTS"
export const LOAD_PB_REPORTS = "LOAD_PB_REPORTS"
export const LOAD_REPORTS = "LOAD_REPORTS";
export const LOAD_REPORTS_SUCCESS = "LOAD_REPORTS_SUCCESS";
export const LOAD_AD_REPORTS_SUCCESS = "LOAD_AD_REPORTS_SUCCESS";
export const LOAD_PB_REPORTS_SUCCESS = "LOAD_PB_REPORTS_SUCCESS";
export const SORT_AD_REPORTS = "SORT_AD_REPORTS";
export const SORT_PB_REPORTS = "SORT_PB_REPORTS";
export const FILTER_DATE = "FILTER_DATE";
export const FILTER_DATE_TYPE = "FILTER_DATE_TYPE";
export const RESET_STATE = "RESET_STATE";

export interface AdminReportsState {
    reports: Array<any>
    adReports: Array<any>
    pbReports: Array<any>
    loading: boolean
    reportType: number
    filterByDateType: {
        label: string
        value: string
    }
    filterByDate: {
        to: Date
        from: Date
    }
    reportSortOptions: {
        field: string
        direction: boolean
    }
}

export interface Report {
    name?: string
}

interface LoadAdminReports {
    type: typeof LOAD_REPORTS_SUCCESS | typeof LOAD_REPORTS
    payload: Array<any>
}

interface LoadAdminAdReports {
    type: typeof LOAD_AD_REPORTS | typeof LOAD_AD_REPORTS_SUCCESS
    payload: Array<any>
}

interface LoadAdminPbReports {
    type: typeof LOAD_PB_REPORTS | typeof LOAD_PB_REPORTS_SUCCESS
    payload: Array<any>
}

interface SortAdminPbReports {
    type: typeof SORT_PB_REPORTS
    payload: any
}

interface SortAdminAdReports {
    type: typeof SORT_AD_REPORTS
    payload: any
}

interface FilterByDate {
    type: typeof FILTER_DATE
    payload: any
}

interface FilterByDateType {
    type: typeof FILTER_DATE_TYPE
    payload: any
}

interface ResetState {
    type: typeof RESET_STATE,
    payload: any
}

export type AdminReportsActionTypes =
    LoadAdminReports |
    LoadAdminAdReports |
    LoadAdminPbReports |
    SortAdminAdReports |
    SortAdminPbReports |
    FilterByDate |
    FilterByDateType |
    ResetState
