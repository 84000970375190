import {
    SitesState,
    Logo,
    LOAD_SITES_SUCCESS,
    SiteActionTypes,
    LogoActionTypes,
    UPDATE_SITE_FIELD,
    LOAD_SITE_BY_ID_SUCCESS,
    LOAD_SITES,
    LOAD_SITE_BY_ID,
    UPDATE_SITE_SUCCESS,
    CREATE_SITE_SUCCESS,
    SORT_SITES,
    UPDATE_LOGO
  } from "./types";
import { sortByField } from "../../lib/sort";
import { TOGGLE_CODE_VISIBILITY } from "../offers/types";
  const emptySite = {
    name: "",
    url: "",
    description: "",
    placeholder1: "Select your free offers below:",
    placeholder2: "As our thanks, please choose any of these free offers below:",
    placeholder3: "Submit & Continue",
    placeholder4: "No thanks...",
    pages: 1,
    offers: 3,
    smartHost: 0,
    hostedSite: "",
    logo: "",
    clientScript: "",
    backgroundColor: "",
    textColor: "",
    excludeCategories: [],
    excludeOffers: [],
    redirectUrl: '',
    images: true,
    liveOfferBackground: 1,
    liveOffer: 1,
    coopAllowed: 0,
    coopCount: 1
  };

const initialSort = {
    field: 'name',
    direction: false
}

const initialState: SitesState = {
    loading: false,
    loadingSite: false,
    sites: [],
    sitesSortOptions: Object.assign({}, initialSort),
    editedSite: Object.assign({}, emptySite),
};

const logoState: Logo = {
    logo: null
};

export function logoReducer(
    state = logoState,
    action: LogoActionTypes
) : Logo {
    switch (action.type) {
        case UPDATE_LOGO:
            console.log(action);
            let img = action.payload;
            return {
                ...state,
                logo: img
            }
    default:
        return state;
    }
}
  
export function sitesReducer(
    state = initialState,
    action: SiteActionTypes
  ): SitesState {
    switch (action.type) {
        case LOAD_SITES:
          return {
            ...state,
            sites: [],
            loading: true
          }
        case LOAD_SITES_SUCCESS:
            return {
                ...state,
                sites: [...action.payload],
                loading: false
            };


        case LOAD_SITE_BY_ID:
            return {
                ...state,
                loadingSite: true
            };

        case LOAD_SITE_BY_ID_SUCCESS:
            return {
                ...state,
                editedSite: action.payload,
                loadingSite: false
            };

        case CREATE_SITE_SUCCESS:
            return {
                ...state,
                editedSite: Object.assign({}, emptySite)
            };

        case UPDATE_SITE_SUCCESS:
            return {
                ...state
            };

        case UPDATE_SITE_FIELD:
            let editedSite = Object.assign({}, state.editedSite);
            editedSite[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedSite
            }

        case SORT_SITES:
            state.sitesSortOptions.direction = !state.sitesSortOptions.direction;
            state.sitesSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                sites: sortByField(state.sitesSortOptions.field, state.sitesSortOptions.direction, state.sites),
                sitesSortOptions: Object.assign({}, state.sitesSortOptions)
            }
        case TOGGLE_CODE_VISIBILITY:
                state.editedSite.showCode = !state.editedSite.showCode;
                return {
                    ...state,
                    editedSite: Object.assign({}, state.editedSite)
                }
      default:
        return state;
    }
  }
  