import { sortAdvertiserReports } from "./actions";
export const LOAD_SITES = "LOAD_SITES";
export const LOAD_SITES_SUCCESS = "LOAD_SITES_SUCCESS";
export const SORT_ADVERTISER_REPORTS = "SORT_REPORTS";
export const LOAD_REPORTS = "LOAD_REPORTS";
export const LOAD_REPORTS_SUCCESS = "LOAD_REPORTS_SUCCESS";
export const ADD_OFFER = "ADD_OFFER";
export const CHANGE_REPORT = "CHANGE_REPORT";
export const LOAD_OFFERS_SUCCESS = "LOAD_OFFERS_SUCCESS";
export const LOAD_OFFERS = "LOAD_OFFERS";
export const FILTER_DATE = "FILTER_DATE";
export const FILTER_DATE_TYPE = "FILTER_DATE_TYPE";
export const RESET_ADV_STATE = "RESET_ADV_STATE";

export interface AdvertiserReportsState {
    reports: Array<any>
    loading: boolean
    reportType: number
    chosenOfferCodes: Array<any>
    filterByDateType: {
        label: string
        value: string
    }
    filterByDate: {
        to: Date
        from: Date
    }
    reportSortOptions: {
        field: string
        direction: boolean
    }
}

export interface DetailReport {
    offeruuid: string,
    userhash: string,
    sitecode: string,
    "userhash+sitecode+offeruuid": string,
    fingerprint: object,
    email: string,
    timestamp: Date,
    form_gclid: any,
    ao_status: any,
    fname: any,
    mbid: any,
    ccid: any,
    page_id: any,
    phone: string,
    siteid: any,
    code: any,
    tambid: any,
    site: any,
    leadmarkstatus: any,
    sitename: string
    responsestatus: any
}

export interface CountReport {
    offeruuid: string,
    userhash: string,
    sitecode: string,
    "userhash+sitecode+offeruuid": string,
    fingerprint: object,
    email: string,
    timestamp: Date,
    form_gclid: any,
    ao_status: any,
    fname: any,
    mbid: any,
    ccid: any,
    page_id: any,
    phone: string,
    siteid: any,
    code: any,
    tambid: any,
    site: any,
    leadmarkstatus: any,
    sitename: string
    responsestatus: any
}

export interface RecogReport {
    offeruuid: string,
    userhash: string,
    sitecode: string,
    "userhash+sitecode+offeruuid": string,
    fingerprint: object,
    email: string,
    timestamp: Date,
    form_gclid: any,
    ao_status: any,
    fname: any,
    mbid: any,
    ccid: any,
    page_id: any,
    phone: string,
    siteid: any,
    code: any,
    tambid: any,
    site: any,
    leadmarkstatus: any,
    sitename: string
    responsestatus: any
}

interface AddOffer {
    type: typeof ADD_OFFER
    payload: Array<Object>
}

interface FilterByDate {
    type: typeof FILTER_DATE
    payload: any
}

interface FilterByDateType {
    type: typeof FILTER_DATE_TYPE
    payload: any
}

interface ChangeType {
    type: typeof CHANGE_REPORT
    payload: number
}

interface LoadOffers {
    type: typeof LOAD_OFFERS_SUCCESS | typeof LOAD_OFFERS
    payload: Array<Object>
}

interface LoadSites {
    type: typeof LOAD_SITES_SUCCESS | typeof LOAD_SITES
    payload: Array<Object>
}

interface LoadAdvertiserReports {
    type: typeof LOAD_REPORTS_SUCCESS | typeof LOAD_REPORTS
    payload: Array<DetailReport>
}

interface SortAdvertiserReports {
    type: typeof SORT_ADVERTISER_REPORTS,
    payload: any
}

interface ResetAdvState {
    type: typeof RESET_ADV_STATE,
    payload: any
}

export type AdvertiserReportsActionTypes = 
    LoadAdvertiserReports |
    SortAdvertiserReports |
    LoadSites | 
    AddOffer |
    ChangeType |
    LoadOffers |
    FilterByDate |
    FilterByDateType |
    ResetAdvState

