import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { campaignsReducer } from "./campaigns/reducers";
import authReducer from "./auth/reducers";
import { offersReducer } from "./offers/reducers";
import { logoReducer, sitesReducer } from "./sites/reducers";
import { accountsReducer } from "./accounts/reducers";
import { publisherReportsReducer } from "./reports/publisher_reports/reducers";
import { adminReportsReducer } from "./reports/admin_reports/reducers";
import systemReducer from "./system/reducers";
import { advertiserReportsReducer } from "./reports/advertiser_reports/reducers";

const rootReducer = combineReducers({
  campaigns: campaignsReducer,
  authReducer: authReducer,
  offers: offersReducer,
  sites: sitesReducer,
  logo: logoReducer,
  accounts: accountsReducer,
  publisherReports: publisherReportsReducer,
  advertiserReports: advertiserReportsReducer,
  adminReports: adminReportsReducer,
  system: systemReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export let store;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const newStore = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  store = newStore;

  return newStore;
}
