import React from 'react';
import './sites.css';
import AppHeader from '../../components/header/header';
import DataTable from '../../components/dataTable';

import { connect } from "react-redux";
import { SitesState } from '../../store/sites/types';
import { AppState } from '../../store';
import { loadSites, sortSites } from '../../store/sites/actions';
import { withRouter } from 'react-router';
import { HashLoader } from "react-spinners";
import { NavLink as Link } from 'react-router-dom';

const spinnerStyle = `
    margin: 100px auto;
`

const cols = [{
    fieldName: "Name",
    fieldValue: "name",
    fieldType: 'string'
}, {
    fieldName: "Code",
    fieldValue: "code",
    fieldType: 'string'
}, {
    fieldName: "Description",
    fieldValue: "description",
    fieldType: 'string'
}, {
    fieldName: "Offers",
    fieldValue: "offers",
    fieldType: 'number'
}, {
    fieldName: "Pages",
    fieldValue: "pages",
    fieldType: 'number'
}]

interface SitesInterfaceProps {
    sites: SitesState,
    loadSites: typeof loadSites
    sortSites: typeof sortSites
    history: any
}

class Sites extends React.Component<SitesInterfaceProps> {

    public componentDidMount():void {
        this.props.loadSites();
    }

    public edit(id): void {
        this.props.history.push(`/site/${id}`);
    }

    public render(): JSX.Element {
        return (
            <div>
                <AppHeader></AppHeader>
                <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.sites.loading} />
                {!this.props.sites.loading ?
                <section>
                    <div className="page__title">
                        <h1 className="section__name">Sites</h1>
                        <Link className="create__btn" to="/sites/create">Create Site</Link>
                    </div>
                    <DataTable orderField={this.props.sites.sitesSortOptions.field} orderDirection={this.props.sites.sitesSortOptions.direction} colClickAction={(col) => this.props.sortSites(col)} emptyText="No Site created yet" rowClickAction={this.edit.bind(this)} cols={cols} rows={this.props.sites.sites}></DataTable>

                </section>
                : null }
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    sites: state.sites,
    loadSites: loadSites,
    sortSites: sortSites
});

export default withRouter(
    connect(mapStateToProps, {
        loadSites,
        sortSites
    })(Sites)
);
