import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import AppHeader from '../../components/header/header';
import './fileupload.css';
//import FormData from 'form-data';
import axios from 'axios';

const acceptedFileType = ["CSV"]

const FileUpload = () => {

  const [cliendId, setClientId] = useState('')
  const [offerId, setOfferId] = useState('')
  const [file, setFile] = useState<File>();
  let success;

  const handleFileUpload = (uploadedFile) => {
    setFile(uploadedFile);
    console.log(file);
  }

  const submitFile = async (e) => {
    e.preventDefault();
    const messageArea = document.querySelector('#message');
    if (file != undefined) {
      console.log(file);
      /*const formData = {
        cliendId,
        offerId,
        file
      }*/
      /*let data = new FormData();
      data.append('', file);
      const messageArea = document.querySelector('#message')

      let config = {
        url: `https://suppression-uploader.smartcoreg.com/api/imports?offerId=${offerId}&clientId=${cliendId}&colName=Email&fileType=raw`,
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": undefined
        },
        data
      }*/
      var formdata = new FormData();
      formdata.append("", file);

      var requestOptions: RequestInit = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(`https://suppression-uploader.smartcoreg.com/api/imports?offerId=${offerId}&clientId=${cliendId}&colName=Email&fileType=raw`, requestOptions)
        .then(res => {
          if (res.status == 200) {
            setClientId('')
            setOfferId('')
            messageArea!.classList.add('success')
            messageArea!.innerHTML = res.statusText;
          } else {
            messageArea!.classList.add('error')
            messageArea!.innerHTML = res.statusText;
          }
        })
        .catch(error => {
          messageArea!.classList.add('error')
          messageArea!.innerHTML = error.statusText;
        });

      /*if (res.status == 200) {
        setClientId('')
        setOfferId('')
        messageArea!.classList.add('success')
        messageArea!.innerHTML = res.statusText;
      } else {
        messageArea!.classList.add('error')
        messageArea!.innerHTML = res.statusText;
      }*/
    }
  }

  return (
    <>
      <AppHeader></AppHeader>
      <article id="file-upload-popup">
        <div className='copy'>
          <h1>Upload File</h1>
          <p id="message"></p>
        </div>
        <form>
          <div className='form-input first'>
            <label>Client ID </label>
            <input type='text' placeholder='Client ID' value={cliendId} onChange={e => { console.log(e.target.value); setClientId(e.target.value) }} />
          </div>
          <div className='form-input second'>
            <label>Offer ID </label>
            <input type='text' placeholder='Offer ID' value={offerId} onChange={e => { console.log(e.target.value); setOfferId(e.target.value) }} />
          </div>
          <FileUploader handleChange={(f) => handleFileUpload(f)} name="file" types={acceptedFileType} />
          <button className='submit-button' type='submit' onClick={(e) => submitFile(e)}>Upload File</button>
        </form>
      </article>
    </>
  )
}

export default FileUpload
