import {
    LOAD_REPORTS_SUCCESS,
    LOAD_REPORTS,
    SORT_PUBLISHER_REPORTS,
    LOAD_SITES,
    LOAD_SITES_SUCCESS,
    ADD_SITE,
    CHANGE_REPORT,
    LOAD_OFFERS,
    LOAD_OFFERS_SUCCESS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_PUB_STATE
} from "./types";
import axios from '../../../lib/axios';
import config from '../../../config/app-config.json';

export const loadPublisherSites = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_SITES,
            payload: []
        })
        axios.get(`${config.serviceUrl}/sites`).then((response) => {
            dispatch({
                type: LOAD_SITES_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const loadOffers = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_OFFERS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/allOffers`).then((response) => {
            dispatch({
                type: LOAD_OFFERS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const loadPublisherReports = (reportType, sites, filterByDate, filterByDateType, leadMarkStatus, smartRecognitionId) => {
    
    return (dispatch: any) => {
        dispatch({
            type: LOAD_REPORTS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/publisher_reports`, {
            params: {
                reportType: `${reportType}`,
                siteCodes: `${sites}`,
                filterByDateTo: `${filterByDate.to}`,
                filterByDateFrom: `${filterByDate.from}`,
                leadMarkStatus: `${leadMarkStatus}`,
                filterByDateType: `${filterByDateType.value}`,
                smartRecognitionId: `${smartRecognitionId}`
            }
        }).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_REPORTS_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const addSite = (value) => {
    return {
        type: ADD_SITE,
        payload: value
    }
}

export const filterByDate = (value) => {
    return {
        type: FILTER_DATE,
        payload: value
    }
}

export const filterByDateType = (value) => {
    return {
        type: FILTER_DATE_TYPE,
        payload: value
    }
}

export const changeType = (value) => {
    return {
        type: CHANGE_REPORT,
        payload: value
    }
}

export const sortPublisherReports = col => {
    return dispatch => dispatch({
        type: SORT_PUBLISHER_REPORTS,
        payload: col
    })
}

export const resetPublisherState = () => {
    return dispatch => dispatch({
        type: RESET_PUB_STATE,
        payload: []
    })
}
