import { Offer } from "../offers/types";


export const LOAD_CAMPAIGNS = "LOAD_CAMPAIGNS";
export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";
export const UPDATE_CAMPAIGN_FIELD = "UPDATE_CAMPAIGN_FIELD";
export const LOAD_CAMPAIGN_BY_ID = "LOAD_CAMPAIGN_BY_ID";
export const LOAD_CAMPAIGN_BY_ID_SUCCESS = "LOAD_CAMPAIGN_BY_ID_SUCCESS";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const REMOVE_CAMPAIGN_BY_ID = "REMOVE_CAMPAIGN_BY_ID";
export const REMOVE_CAMPAIGN_BY_ID_SUCCESS = "REMOVE_CAMPAIGN_BY_ID_SUCCESS";
export const LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS = "LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS";
export const ASSIGN_OFFER_TO_CAMPAIGN = "ASSIGN_OFFER_TO_CAMPAIGN";
export const ASSIGN_OFFER_TO_CAMPAIGN_SAVE = "ASSIGN_OFFER_TO_CAMPAIGN_SAVE";
export const SORT_CAMPAIGNS = "SORT_CAMPAIGNS";
export const CLONE_CAMPAIGN_SUCCESS = "CLONE_CAMPAIGN_SUCCESS"

export interface CampaignsState {
    campaigns: Array<Campaign>
    campaignsSortOptions: {
        field: string
        direction: boolean
    }
    editedCampaign: Campaign
    loading: boolean
    loadingCampaign: boolean
    editedCampaignOffers: Array<Offer>
}

export interface Campaign {
    id?: number
    name: string
    start: string
    end: string | undefined
    status: string
    dailyLeadCap?: number
    totalLeadAmount?: number
    totalBudget: number
    assignedOffersIds?: Array<any>
    allowedStatusList: Array<string>
}

interface LoadCampaigns {
    type: typeof LOAD_CAMPAIGNS_SUCCESS | typeof LOAD_CAMPAIGNS,
    payload: Array<Campaign>
}

interface LoadCampaignById {
    type: typeof LOAD_CAMPAIGN_BY_ID_SUCCESS | typeof LOAD_CAMPAIGN_BY_ID,
    payload: Campaign
}

interface UpdateCampaignField {
    type: typeof UPDATE_CAMPAIGN_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateCampaign {
    type: typeof UPDATE_CAMPAIGN_SUCCESS
    payload: any
}

interface CreateCampaign {
    type: typeof CREATE_CAMPAIGN_SUCCESS
    payload: any
}

interface RemoveCampaign {
    type: typeof REMOVE_CAMPAIGN_BY_ID_SUCCESS
    payload: any
}

interface loadOffersByCampaignId {
    type: typeof LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS
    payload: any
}

interface SortCampaigns {
    type: typeof SORT_CAMPAIGNS,
    payload: any
}

interface DefaultAction {
    type: any
    payload?: any
}

export type CampaignActionTypes = LoadCampaigns | UpdateCampaignField | LoadCampaignById | LoadCampaigns | CreateCampaign | UpdateCampaign | RemoveCampaign | loadOffersByCampaignId | DefaultAction | SortCampaigns;
