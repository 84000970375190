import React from 'react';
import AppHeader from '../../components/header/header';
import { connect } from "react-redux";
import { AppState } from '../../store';
import { withRouter } from 'react-router';
import { HashLoader } from "react-spinners";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { SystemState } from '../../store/system/types';
import { AccountsState } from '../../store/accounts/types';
import { loadAccountById, loadAccounts, updateAccountField, saveAccount } from '../../store/accounts/actions';
import StickySaveBar from '../../components/stickySaveBar/stickySaveBar';
import config from '../../config/app-config.json';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Highlight from 'react-highlight';

const spinnerStyle = `
    margin: 100px auto;
`

interface AccountInterfaceProps {
    accounts: AccountsState,
    system: SystemState,
    // updateOfferField: Function
    loadAccountById: Function
    loadAccounts: Function
    updateAccountField: Function
    saveAccount: Function
    // removeOffer: Function
    // loadCategories: Function
    match: {
        params: {
            id: number
        }
    },
    history: any
}

class MyAccountEdit extends React.Component<AccountInterfaceProps> {

    public componentDidMount(): void {

    }

    private handleChange(field: string, value: any): void {
        // if(field === 'categoryId' || field === 'mobileDesktop') {
        //     value = value.value;
        // }
        this.props.updateAccountField(field, value);
    }

    private handleSubmit(event): void {
        event.preventDefault();
        this.props.saveAccount(this.props.accounts.editedAccount, this.props.history);
    }

    //THIS NEEDS TO BE FIXED ISSUE OCCURS WHEN USING config.recUrl

    private getScript(): string {
        return `<script src="https://main.d2ep2n36rh5ifz.amplifyapp.com/recog.min.js?recog=true&publisher=${this!.props!.accounts!.editedAccount!.id}"></script>`;
    }
    // private remove(id: any): void {
    //     if(window.confirm('Are you sure you want to delete the Offer?') )
    //         this.props.removeOffer(id, this.props.history);
    // }


    public render(): JSX.Element {
        console.log(this);
        return (
            <div>
                <AppHeader></AppHeader>
                <HashLoader
                    //css={spinnerStyle}
                    size={100}
                    color={"#297DAC"}
                    loading={this.props.accounts.loadingAccount} />
                {!this.props.accounts.loadingAccount && this.props.accounts.editedAccount !== null ?

                    <form className="sections__form" onSubmit={this.handleSubmit.bind(this)}>
                        <StickySaveBar />
                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 className="section__name" style={{ padding: '0 0 10px 0' }}>{this.props.accounts.editedAccount.name} - Company Information</h1>
                            </div>

                            <HashLoader
                                //css={spinnerStyle}
                                size={100}
                                color={"#297DAC"}
                                loading={this.props.accounts.loadingAccount} />


                            {!this.props.accounts.loadingAccount ?
                                <div>
                                    <div className="flexBox">
                                        <div>
                                            <label>Company name</label>
                                            <div>
                                                <input required type="text" value={this.props.accounts.editedAccount.name !== null ? this.props.accounts.editedAccount.name : ''} onChange={event => this.handleChange('name', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Email</label>
                                            <div>
                                                <input required type="text" value={this.props.accounts.editedAccount.email !== null ? this.props.accounts.editedAccount.email : ''} onChange={event => this.handleChange('email', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>URL</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.url !== null ? this.props.accounts.editedAccount.url : ''} onChange={event => this.handleChange('url', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Address</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.address !== null ? this.props.accounts.editedAccount.address : ''} onChange={event => this.handleChange('address', event.target.value)} />
                                            </div>
                                        </div>


                                        <div>
                                            <label>Second Address</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.address2 !== null ? this.props.accounts.editedAccount.address2 : ''} onChange={event => this.handleChange('address2', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>City</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.city !== null ? this.props.accounts.editedAccount.city : ''} onChange={event => this.handleChange('city', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>State</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.state !== null ? this.props.accounts.editedAccount.state : ''} onChange={event => this.handleChange('state', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>ZIP</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.zip !== null ? this.props.accounts.editedAccount.zip : ''} onChange={event => this.handleChange('zip', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Accounting Contact Name</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.accContactName !== null ? this.props.accounts.editedAccount.accContactName : ''} onChange={event => this.handleChange('accContactName', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Accounting Email</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.accEmail !== null ? this.props.accounts.editedAccount.accEmail : ''} onChange={event => this.handleChange('accEmail', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Routing Number</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.routingNumber !== null ? this.props.accounts.editedAccount.routingNumber : ''} onChange={event => this.handleChange('routingNumber', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Account Number</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.accountNumber !== null ? this.props.accounts.editedAccount.accountNumber : ''} onChange={event => this.handleChange('accountNumber', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Bank Name</label>
                                            <div>
                                                <input type="text" value={this.props.accounts.editedAccount.bankName !== null ? this.props.accounts.editedAccount.bankName : ''} onChange={event => this.handleChange('bankName', event.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </section>


                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 className="section__name" style={{ padding: '0 0 10px 0' }}>Contact Information</h1>
                            </div>

                            <HashLoader
                                //css={spinnerStyle}
                                size={100}
                                color={"#297DAC"}
                                loading={this.props.accounts.loadingAccount} />


                            {!this.props.accounts.loadingAccount ?
                                <div>
                                    <div className="flexBox">
                                        <div>
                                            <label>Contact Name</label>
                                            <div>
                                                <input required type="text" value={this.props.accounts.editedAccount.contactName !== null ? this.props.accounts.editedAccount.contactName : ''} onChange={event => this.handleChange('contactName', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Contact Email</label>
                                            <div>
                                                <input required type="email" value={this.props.accounts.editedAccount.contactEmail !== null ? this.props.accounts.editedAccount.contactEmail : ''} onChange={event => this.handleChange('contactEmail', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Second Email</label>
                                            <div>
                                                <input type="email" value={this.props.accounts.editedAccount.contactEmail2 !== null ? this.props.accounts.editedAccount.contactEmail2 : ''} onChange={event => this.handleChange('contactEmail2', event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Contact Phone</label>
                                            <div>
                                                <input required type="text" value={this.props.accounts.editedAccount.contactPhone !== null ? this.props.accounts.editedAccount.contactPhone : ''} onChange={event => this.handleChange('contactPhone', event.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </section>

                        {(this.props.system.isAdmin) ?
                            <section style={{
                                padding: '20px 20px 20px'
                            }}>
                                <div className="page__title">
                                    <h1 className="section__name" style={{ padding: '0 0 10px 0' }}>Admin Information</h1>
                                </div>

                                <HashLoader
                                    //css={spinnerStyle}
                                    size={100}
                                    color={"#297DAC"}
                                    loading={this.props.accounts.loadingAccount} />


                                {!this.props.accounts.loadingAccount ?
                                    <div>
                                        <div style={{ margin: '15px' }}>
                                            <div>
                                                <label>
                                                    <span>
                                                        {`Client ID: ${this.props.system.selectedClient.id}`}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flexBox">
                                            <div style={{ margin: '15px' }}>
                                                <div>
                                                    <label className="checkbox" style={{
                                                        'marginLeft': 0,
                                                        'marginTop': '10px'
                                                    }}>
                                                        <input type="checkbox" onChange={event => this.handleChange('unlimitedLeads', this!.props!.accounts!.editedAccount!.unlimitedLeads! == 1 ? 0 : 1)} checked={this!.props!.accounts!.editedAccount!.unlimitedLeads! == 1} />
                                                        <span className="checkmark"></span>
                                                        <span style={{
                                                            top: '5px',
                                                            position: 'relative'
                                                        }}>Unlimited Leads Per Day</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {this!.props!.accounts!.editedAccount!.unlimitedLeads == 0 ?
                                                <>
                                                    <div>
                                                        <label>Leads Allowed Per Day</label>
                                                        <div>
                                                            <input required type="number" value={this.props.accounts.editedAccount.leadsPerDay !== null ? this.props.accounts.editedAccount.leadsPerDay : 0} onChange={event => this.handleChange('leadsPerDay', event.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label>Redirect URL</label>
                                                        <div>
                                                            <input required type="text" value={this.props.accounts.editedAccount.redirectUrl} onChange={event => this.handleChange('redirectUrl', event.target.value)} />
                                                        </div>
                                                    </div>
                                                </>
                                                : null}

                                            <div style={{ margin: '15px' }}>
                                                <div>
                                                    <label className="checkbox" style={{
                                                        'marginLeft': 0,
                                                        'marginTop': '10px'
                                                    }}>
                                                        <input type="checkbox" onChange={event => this.handleChange('leadStatusReporting', this!.props!.accounts!.editedAccount!.leadStatusReporting! == 1 ? 0 : 1)} checked={this!.props!.accounts!.editedAccount!.leadStatusReporting! == 1} />
                                                        <span className="checkmark"></span>
                                                        <span style={{
                                                            top: '5px',
                                                            position: 'relative'
                                                        }}>Lead Status Reporting</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{ margin: '15px' }}>
                                                <div>
                                                    <label className="checkbox" style={{
                                                        'marginLeft': 0,
                                                        'marginTop': '10px'
                                                    }}>
                                                        <input type="checkbox" onChange={event => this.handleChange('smartRecognition', this!.props!.accounts!.editedAccount!.smartRecognition! == 1 ? 0 : 1)} checked={this!.props!.accounts!.editedAccount!.smartRecognition! == 1} />
                                                        <span className="checkmark"></span>
                                                        <span style={{
                                                            top: '5px',
                                                            position: 'relative'
                                                        }}>Smart Recognition</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    {this!.props!.accounts!.editedAccount!.smartRecognition == 1 ?
                                                        <>
                                                            <div>
                                                                <Highlight className='html'>
                                                                    {this.getScript()}
                                                                </Highlight>
                                                                <div>
                                                                    <CopyToClipboard text={this.getScript()}>
                                                                        <button className="tabs" onClick={(event) => event.preventDefault()}>Copy to clipboard</button>
                                                                    </CopyToClipboard>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </section> : null}
                    </form>
                    : null}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAccountField: (field, value) => dispatch(updateAccountField(field, value)),
        loadAccounts: history => dispatch(loadAccounts(history)),
        loadAccountById: id => dispatch(loadAccountById(id)),
        saveAccount: (account, history) => dispatch(saveAccount(account, history)),
        //    removeOffer: (id, history) => dispatch(removeOfferById(id, history) ),
        //    loadCategories: () => dispatch(loadCategories() )
    }
}

const mapStateToProps = (state: AppState) => ({
    accounts: state.accounts,
    system: state.system
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MyAccountEdit)
);
