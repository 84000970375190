import React from 'react';
import './menu.css';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types';
import { AccountsState } from '../../store/accounts/types';
import { loadAccounts, filterClients } from '../../store/accounts/actions';
import { toggleClientMenu, selectClient, menuToggle } from '../../store/system/actions';
import { Session } from '../../store/auth/types';


const mapDispatchToProps = dispatch => {
    return {
        loadAccounts: () => dispatch(loadAccounts() ),
        menuToggle: () => dispatch(menuToggle() )
    }
  }

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    accounts: state.accounts
})


class AppMenu extends React.Component<any> {
    componentDidMount() {
        if(document.location.href.indexOf('callback') === -1)
            this.props.loadAccounts();
    }

    render() {
        if(this.props.system.selectedClient === null)
            return null;
        return (
            <div>
                <div className={`menu ${this.props.system.menuVisible ? 'visible': 'hidden'}`}>
                    <a className="app__logo" href="/">
                        <img alt="" src="/assets/logo.png" />
                    </a>

                    <div className="app__menu-links">
                        {(this.props.system.isAdmin) ?<>
                        <Link onClick={this.props.menuToggle} to="/account">My Account</Link>
                        <Link onClick={this.props.menuToggle} to="/campaigns">Campaigns</Link>
                        <Link onClick={this.props.menuToggle} to="/offers">Offers</Link>
                        <Link onClick={this.props.menuToggle} to="/sites">Sites</Link></> : null}


                        <Link onClick={this.props.menuToggle} to="/publisher_reports">Publisher Reports</Link>

                        <Link onClick={this.props.menuToggle} to="/advertiser_reports">Advertiser Reports</Link>

                        <Link onClick={this.props.menuToggle} to="/uploader">File Upload</Link>
                        
                        {this.props.system.isAdmin ? <Link onClick={this.props.menuToggle} to="/admin_reports">Admin Reports</Link> : ''}

                    </div>
                </div>

                <div className={`app__container ${this.props.system.menuVisible ? 'app__container--menu-visible': ''}`}>
                    {this.props.children}
                </div>
           </div>
        )
    }

}

//REMOVED LINK: <Link onClick={this.props.menuToggle} to="/reports">Reports</Link>
// {(this.props.system.selectedClient.clientType == 2 || this.props.system.selectedClient.clientType == 0) ? <>
// </> : null }

// {(this.props.system.selectedClient.clientType == 2 || this.props.system.selectedClient.clientType == 1) ? <>
// </> : null }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppMenu));
