import {
    LOAD_REPORTS_SUCCESS,
    LOAD_REPORTS,
    SORT_ADVERTISER_REPORTS,
    LOAD_SITES,
    LOAD_SITES_SUCCESS,
    ADD_OFFER,
    CHANGE_REPORT,
    LOAD_OFFERS,
    LOAD_OFFERS_SUCCESS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_ADV_STATE
} from "./types";
import axios from '../../../lib/axios';
import config from '../../../config/app-config.json';

export const loadAdvertiserOffers = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_OFFERS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/offers`).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_OFFERS_SUCCESS,
                payload: response.data.data
            });
        })
    }
}

export const loadSites = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_SITES,
            payload: []
        })
        axios.get(`${config.serviceUrl}/sites`).then((response) => {
            dispatch({
                type: LOAD_SITES_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const loadAdvertiserReports = (reportType, offers, filterByDate, filterByDateType, leadMarkStatus, smartRecognitionId) => {

    return (dispatch: any) => {
        dispatch({
            type: LOAD_REPORTS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/advertiser_reports`, {
            params: {
                reportType: `${reportType}`,
                offerCodes: `${offers}`,
                filterByDateTo: `${filterByDate.to}`,
                filterByDateFrom: `${filterByDate.from}`,
                leadMarkStatus: `${leadMarkStatus}`,
                filterByDateType: `${filterByDateType.value}`,
                smartRecognitionId: `${smartRecognitionId}`
            }
        }).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_REPORTS_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const addOffer = (value) => {
    return {
        type: ADD_OFFER,
        payload: value
    }
}

export const filterByDate = (value) => {
    return {
        type: FILTER_DATE,
        payload: value
    }
}

export const filterByDateType = (value) => {
    return {
        type: FILTER_DATE_TYPE,
        payload: value
    }
}

export const changeType = (value) => {
    return {
        type: CHANGE_REPORT,
        payload: value
    }
}

export const sortAdvertiserReports = col => {
    return dispatch => dispatch({
        type: SORT_ADVERTISER_REPORTS,
        payload: col
    })
}

export const resetAdvertiserState = () => {
    return dispatch => dispatch({
        type: RESET_ADV_STATE,
        payload: []
    })
}
