

export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS";
export const LOAD_ACCOUNTS_SUCCESS = "LOAD_ACCOUNTS_SUCCESS";
export const UPDATE_ACCOUNT_FIELD = "UPDATE_ACCOUNT_FIELD";
export const LOAD_ACCOUNT_BY_ID = "LOAD_ACCOUNT_BY_ID";
export const LOAD_ACCOUNT_BY_ID_SUCCESS = "LOAD_ACCOUNT_BY_ID_SUCCESS";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const FILTER_ACCOUNTS = "FILTER_ACCOUNTS";

export interface AccountsState {
    loading: boolean
    loadingAccount: boolean
    accounts: Array<Account>
    accountsFiltered: Array<Account>
    editedAccount: Account | null
}

export interface Account {
    id: number
    email: string
    name: string
    url: string
    phone: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    accContactName: string
    accEmail: string
    routingNumber: string
    accountNumber: string
    bankName: string
    contactName: string
    contactEmail: string
    contactEmail2: string
    contactPhone: string
    leadsPerDay: number
    unlimitedLeads: number
    redirectUrl: string
    leadStatusReporting: number
    smartRecognition: number
}

export interface Category {
    id: number
    name: string
}


interface LoadAccounts {
    type: typeof LOAD_ACCOUNTS_SUCCESS | typeof LOAD_ACCOUNTS,
    payload: Array<Account>
}

interface LoadAccountById {
    type: typeof LOAD_ACCOUNT_BY_ID_SUCCESS | typeof LOAD_ACCOUNT_BY_ID,
    payload: Account
}

interface UpdateAccountField {
    type: typeof UPDATE_ACCOUNT_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateAccount {
    type: typeof UPDATE_ACCOUNT_SUCCESS,
    payload: any
}

interface FilterAccounts {
    type: typeof FILTER_ACCOUNTS,
    payload: any
}

export type AccountActionTypes = LoadAccounts | UpdateAccountField | LoadAccountById | LoadAccounts | UpdateAccount | FilterAccounts;
