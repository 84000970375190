import {
    AdminReportsState,
    LOAD_REPORTS,
    LOAD_AD_REPORTS,
    LOAD_PB_REPORTS,
    LOAD_REPORTS_SUCCESS,
    LOAD_AD_REPORTS_SUCCESS,
    LOAD_PB_REPORTS_SUCCESS,
    SORT_AD_REPORTS,
    SORT_PB_REPORTS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_STATE,
    AdminReportsActionTypes,
  } from "./types";
  import { sortByField } from "../../../lib/sort";

  const initialSort = {
    field: 'timestamp',
    direction: false
}

  const initialState: AdminReportsState = {
    loading: false,
    reportType: 0,
    reportSortOptions: initialSort,
    reports: [],
    adReports: [],
    pbReports: [],
    filterByDate: { to: new Date(new Date(Date.now()).toISOString().split('T')[0]), from: new Date(new Date(Date.now()).toISOString().split('T')[0]) },
    filterByDateType: { label: 'Last 7 days', value: 'Last 7 days' }
  };

  export function adminReportsReducer(
    state = initialState,
    action: AdminReportsActionTypes
  ): AdminReportsState {
    console.log('ACTION:', action)
    switch (action.type) {
      case LOAD_AD_REPORTS_SUCCESS:
        return {
          ...state,
          loading: false,
          reports: [...action.payload],
          adReports: [...action.payload]
      }

      case LOAD_PB_REPORTS_SUCCESS:
        return {
          ...state,
          loading: false,
          reports: [...action.payload],
          pbReports: [...action.payload]
        }

      case LOAD_AD_REPORTS:
        return {
          ...state,
          reportType: 0,
          adReports: [...action.payload],
          loading: true,
        };

      case LOAD_PB_REPORTS:
        return {
          ...state,
          reportType: 1,
          pbReports: [...action.payload],
          loading: true,
        };

      case SORT_PB_REPORTS:
        state.reportSortOptions.direction = !state.reportSortOptions.direction;
        state.reportSortOptions.field = action.payload.fieldValue;

        return {
            ...state,
            pbReports: sortByField(state.reportSortOptions.field, state.reportSortOptions.direction, state.pbReports),
            reportSortOptions: Object.assign({}, state.reportSortOptions)
        }

      case SORT_AD_REPORTS:
        state.reportSortOptions.direction = !state.reportSortOptions.direction;
        state.reportSortOptions.field = action.payload.fieldValue;

        return {
            ...state,
            adReports: sortByField(state.reportSortOptions.field, state.reportSortOptions.direction, state.adReports),
            reportSortOptions: Object.assign({}, state.reportSortOptions)
        }

      case FILTER_DATE:
        console.log(action);
        console.log(state);
        return {
          ...state,
          filterByDate: action.payload
        };

      case FILTER_DATE_TYPE:
        return {
          ...state,
          filterByDateType: action.payload
        };

        case RESET_STATE:
          return {...initialState}

    default:
      return state;
    }
  }
