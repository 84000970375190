import React from 'react';
import './campaigns.css';
import AppHeader from '../../components/header/header';
import DataTable from '../../components/dataTable';

import { connect } from "react-redux";
import { CampaignsState } from '../../store/campaigns/types';
import { AppState } from '../../store';
import { updateCampaignField, loadCampaignById, saveCampaign, removeCampaignById, assignOfferToCampaign, emptyCampaign } from '../../store/campaigns/actions';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import moment from 'moment';
import { HashLoader } from "react-spinners";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { loadOffersCampaigns } from '../../store/offers/actions';
import { OffersState } from '../../store/offers/types';
import Select from 'react-select';
import StickySaveBar from '../../components/stickySaveBar/stickySaveBar';

const spinnerStyle = `
    margin: 100px auto;
`


const cols = [{
    fieldName: "Title",
    fieldValue: "title",
    fieldType: 'string'
}, {
    fieldName: "Offered by",
    fieldValue: "offeredBy",
    fieldType: 'string'
}, {
    fieldName: "Payout Lead",
    fieldValue: "payoutLead",
    fieldType: 'number'
}, {
    fieldName: "Daily Lead Cap",
    fieldValue: "dailyLeadCap",
    fieldType: 'number'
}, {
    fieldName: "Total Lead Amount",
    fieldValue: "totalLeadAmount",
    fieldType: 'number'
}]

interface CampaignEditInterfaceProps {
    campaigns: CampaignsState,
    offers: OffersState,
    updateCampaignField: Function
    loadCampaignById: Function
    saveCampaign: Function,
    removeCampaign: Function,
    loadOffersCampaigns: Function,
    assignOfferToCampaign: Function,
    emptyCampaign: typeof emptyCampaign,
    match: {
        params: {
            id: number
        }
    },
    history: any
}

class CampaignEdit extends React.Component<CampaignEditInterfaceProps> {

    public componentDidMount(): void {
        if(this.props.match.params.id) {
            this.props.loadCampaignById(this.props.match.params.id);
        } else {
            this.props.emptyCampaign();
        }
        this.props.loadOffersCampaigns();
    }

    private handleChange(field: string, value: any): void {
        console.log(value);
        if(field ==='status') {
            value = value.value;
        }
        this.props.updateCampaignField(field, value);
    }

    private handleSubmit(event): void {
        event.preventDefault();
        this.props.saveCampaign(this.props.campaigns.editedCampaign, this.props.history);
    }

    private remove(id: any): void {
        if(window.confirm('Are you sure you want to delete the Campaign?') )
            this.props.removeCampaign(id, this.props.history);
    }

    private showOffer(id: number): void {
        this.props.history.push(`/offer/${id}`);
    }

    private assignOffer(value): void {
        this.props.assignOfferToCampaign(value);
    }

    private isDateValid(date) {
        return !date.isBefore(moment().add(-1, 'days') );
    }


    public render(): JSX.Element {
        console.log(this);
        return (
            <div>
                <AppHeader></AppHeader>
                <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.campaigns.loadingCampaign} />
                {!this.props.campaigns.loadingCampaign ?
                <form className="standard__form" onSubmit={this.handleSubmit.bind(this)}>
                    <StickySaveBar />
                    <section style={{
                        padding: '0 20px 20px 20px'
                    }}>


                        <div className="page__title">
                            <h1 className="section__name" style={{padding: '0 0 10px 0'}}>Campaign - {this.props.campaigns.editedCampaign.name}</h1>
                            {this.props.campaigns.editedCampaign.id ? <button className="remove__icon" onClick={() => this.remove(this.props.campaigns.editedCampaign.id)}><img height="30" alt="" src="/assets/remove.png" /></button> : null}
                        </div>
                        <div className="flexBox">
                            <div>
                                <label>Name</label>
                                <div>
                                    <input required type="text" value={this.props.campaigns.editedCampaign.name} onChange={event => this.handleChange('name', event.target.value)} />
                                </div>
                            </div>

                            <div>
                                <label>Start Date</label>

                                <div>
                                    {this.props.campaigns.editedCampaign.status === 'Running' ? <input value={moment(this.props.campaigns.editedCampaign.start).format('MM/DD/YYYY h:mm A')} type="text" disabled={true} />: <Datetime isValidDate={this.isDateValid} onChange={date => this.handleChange('start', moment(date).utc().format())} dateFormat="MM/DD/YYYY" timeFormat="h:mm A" value={moment(this.props.campaigns.editedCampaign.start).format('MM/DD/YYYY h:mm A')} />}
                              </div>
                            </div>

                            <div>
                                <label>End Date</label>
                                <div>
                                    <Datetime isValidDate={this.isDateValid} onChange={date => this.handleChange('end', moment(date).utc().format())} dateFormat="MM/DD/YYYY" timeFormat="h:mm A" value={this.props.campaigns.editedCampaign.end ? moment(this.props.campaigns.editedCampaign.end).format('MM/DD/YYYY h:mm A') : undefined} />
                                </div>
                            </div>

                            <div>
                                <label>Assigned Offers</label>
                                <div className="select">
                                    <Select
                                        value={this.props.campaigns.editedCampaign.assignedOffersIds === null ? [] : this.props.campaigns.editedCampaign.assignedOffersIds}
                                        onChange={this.assignOffer.bind(this)}
                                        isMulti={true}
                                        options={this.props.offers.availableOffers.map(offer => {return {
                                            value: offer.id,
                                            label: offer.name
                                        }})}
                                    />
                                </div>
                            </div>

                            <div>
                                <label>Status</label>
                                <div>
                                    <Dropdown options={this.props.campaigns.editedCampaign.allowedStatusList} onChange={status => this.handleChange('status', status)} value={this.props.campaigns.editedCampaign.status} placeholder="Select status" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section style={{
                        padding: '0 20px 20px 20px'
                    }}>

                        <div className="page__title">
                            <h1 className="section__name" style={{padding: '20px 0 10px 0'}}>Leads</h1>
                        </div>
                        <div className="flexBox">
                            <div>
                                <label>Daily Lead Cap</label>
                                <div>
                                    <input step="any" pattern="[0-9]{3}" required type="number" value={this.props.campaigns.editedCampaign.dailyLeadCap} onChange={event => this.handleChange('dailyLeadCap', event.target.value)} />
                                </div>
                            </div>

                            <div>
                                <label>Total Leads Ordered [USD]</label>
                                <div>
                                    <input step="any" required type="number" value={this.props.campaigns.editedCampaign.totalLeadAmount} onChange={event => this.handleChange('totalLeadAmount', event.target.value)} />
                                </div>
                            </div>

                            <div>
                                <label>Total Budget</label>
                                <div>
                                    <input step={0.01} min={0} required type="number" value={this.props.campaigns.editedCampaign.totalBudget} onChange={event => this.handleChange('totalBudget', event.target.value)} />
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
                : null}



                {(this.props.campaigns.loadingCampaign || !this.props.campaigns.editedCampaign.id) ? null :
                    <section>
                        <div className="page__title">
                            <h1 className="section__name">Assigned Offers</h1>
                        </div>

                        <DataTable rowClickAction={this.showOffer.bind(this)} emptyText="No offers assigned yet." cols={cols} rows={this.props.campaigns.editedCampaignOffers}></DataTable>
                    </section>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
       updateCampaignField: (field, value) => dispatch(updateCampaignField(field, value)),
       loadCampaignById: id => dispatch(loadCampaignById(id) ),
       saveCampaign: (campaign, history) => dispatch(saveCampaign(campaign, history) ),
       removeCampaign: (id, history) => dispatch(removeCampaignById(id, history) ),
       loadOffersCampaigns: () => dispatch(loadOffersCampaigns() ),
       assignOfferToCampaign: option => dispatch(assignOfferToCampaign(option) ),
       emptyCampaign: () => dispatch(emptyCampaign() )
    }
  }

const mapStateToProps = (state: AppState) => ({
    campaigns: state.campaigns,
    offers: state.offers
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CampaignEdit)
);
