import React from 'react';
import './dataTable.css';

const DataTable: React.FC<any> = props => {
    const getValue = (row: any, col: any, i: number) => {
        if(col.fieldType === 'date') {
            return new Date(row[col.fieldValue]).toLocaleDateString();
        } else if(col.fieldType === 'button') {
            return <button className="button accent td_button" onClick={(event) => {event.preventDefault(); event.stopPropagation(); props?.buttonAction(row.id)}}>{col.fieldName}</button>

        }
        return row[col.fieldValue];
    }

    const renderSortButton = (col): JSX.Element => {
        let className = 'order__btn';
        if(props.orderField === col.fieldValue) {
            className += ` ${props.orderDirection ? 'desc' : 'asc'}`;
        }
        return (
            <button className={className}>&lsaquo;</button>
        )
    }

    return (
        <table className="data__table">
            <thead>
                <tr>
                    {props.cols.map((col: any, i: number) => 
                        <th onClick={() => props.colClickAction ? props.colClickAction(col) : () => {}} key={i}>{col.fieldName}
                            {renderSortButton(col) }
                        </th>)}
                </tr>
            </thead>

            <tbody>
                {props.rows.map((row: any, i: number) => 
                    <tr key={`row_${i}`}>
                        {props.cols.map((col: any, j: any) => {
                            return (<td onClick={() => props.rowClickAction(row.id)} key={`row_col_${i}_${j}`}>{getValue(row, col, i)}</td>)
                        })}
                    </tr>
                )}

                {(props.rows.length === 0) ? <tr><td colSpan={props.cols.length}>{props.emptyText}</td></tr> : null}
            </tbody>
        </table>
    )
}

export default DataTable;