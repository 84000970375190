import {
  AdvertiserReportsState,
  LOAD_REPORTS,
  LOAD_REPORTS_SUCCESS,
  AdvertiserReportsActionTypes,
  SORT_ADVERTISER_REPORTS,
  ADD_OFFER,
  CHANGE_REPORT,
  FILTER_DATE,
  FILTER_DATE_TYPE,
  RESET_ADV_STATE
} from "./types";
import { sortByField } from "../../../lib/sort";
import { filterByDate } from "./actions";

const initialSort = {
  field: 'timestamp',
  direction: false
}

const initialState: AdvertiserReportsState = {
  reports: [],
  loading: false,
  reportSortOptions: initialSort,
  chosenOfferCodes: [],
  reportType: 4,
  filterByDate: { to: new Date(new Date(Date.now()).toISOString().split('T')[0]), from: new Date(new Date(Date.now()).toISOString().split('T')[0]) },
  filterByDateType: { label: 'Last 7 days', value: 'Last 7 days' }
};

export function advertiserReportsReducer(
  state = initialState,
  action: AdvertiserReportsActionTypes
): AdvertiserReportsState {
  console.log(action);
  switch (action.type) {
    case LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: [...action.payload]
      };

    case LOAD_REPORTS:
      return {
        ...state,
        loading: true
      };

    case ADD_OFFER:
      return {
        ...state,
        chosenOfferCodes: action.payload
      };

    case FILTER_DATE:
      console.log(action);
      console.log(state);
      return {
        ...state,
        filterByDate: action.payload
      };

    case FILTER_DATE_TYPE:
      return {
        ...state,
        filterByDateType: action.payload
      };

    case CHANGE_REPORT:
      return {
        ...state,
        reportType: action.payload
      };

    case SORT_ADVERTISER_REPORTS:
      state.reportSortOptions.direction = !state.reportSortOptions.direction;
      state.reportSortOptions.field = action.payload.fieldValue;

      return {
        ...state,
        reports: sortByField(state.reportSortOptions.field, state.reportSortOptions.direction, state.reports),
        reportSortOptions: Object.assign({}, state.reportSortOptions)
      }

    case RESET_ADV_STATE:
      return {
        reports: [],
        loading: false,
        reportSortOptions: initialSort,
        chosenOfferCodes: [],
        reportType: 4,
        filterByDate: { to: new Date(new Date(Date.now()).toISOString().split('T')[0]), from: new Date(new Date(Date.now()).toISOString().split('T')[0]) },
        filterByDateType: { label: 'Last 7 days', value: 'Last 7 days' }
      }

    default:
      return state;
  }
}
