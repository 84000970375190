import {
    CampaignsState,
    LOAD_CAMPAIGNS_SUCCESS,
    CampaignActionTypes,
    UPDATE_CAMPAIGN_FIELD,
    LOAD_CAMPAIGN_BY_ID_SUCCESS,
    LOAD_CAMPAIGNS,
    LOAD_CAMPAIGN_BY_ID,
    UPDATE_CAMPAIGN_SUCCESS,
    CREATE_CAMPAIGN_SUCCESS,
    LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS,
    ASSIGN_OFFER_TO_CAMPAIGN,
    SORT_CAMPAIGNS
} from "./types";
import moment from "moment";
import { sortByField } from "../../lib/sort";
const emptyCampaign = { name: '', start: moment().format(), end: undefined, leadCap: 0, totalBudget: 0, status: 'Pending', assignedOffersIds: [], allowedStatusList: ['Pending', 'Running'] };
const initialSort = {
    field: 'end',
    direction: false
}

const initialState: CampaignsState = {
    loading: false,
    loadingCampaign: false,
    campaigns: [],
    campaignsSortOptions: Object.assign({}, initialSort),
    editedCampaign: Object.assign({}, emptyCampaign),
    editedCampaignOffers: []
};

export function campaignsReducer(
    state = initialState,
    action: CampaignActionTypes
): CampaignsState {
    switch (action.type) {
        case LOAD_CAMPAIGNS:
            return {
                ...state,
                campaigns: [],
                loading: true
            }
        case LOAD_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaigns: sortByField(state.campaignsSortOptions.field, state.campaignsSortOptions.direction, action.payload),
                loading: false
            };

        case LOAD_CAMPAIGN_BY_ID:
            return {
                ...state,
                loadingCampaign: true
            };

        case LOAD_CAMPAIGN_BY_ID_SUCCESS:
            let current = action.payload.status.toLowerCase();
            switch (current) {
                case 'pending':
                    action.payload.allowedStatusList = ['Pending', 'Running', 'Retired'];
                    break;

                case 'running':
                    action.payload.allowedStatusList = ['Running', 'Paused', 'Retired'];
                    break;

                case 'stopped':
                    action.payload.allowedStatusList = ['Stopped', 'Running', 'Retired', 'Fulfilled'];
                    break;

                case 'paused':
                    action.payload.allowedStatusList = ['Paused', 'Running', 'Retired', 'Fulfilled'];
                    break;

                case 'fulfilled':
                    action.payload.allowedStatusList = ['Paused', 'Running', 'Retired'];
                    break;

                default:
                    action.payload.allowedStatusList = ['Retired'];
                    break;
            }

            return {
                ...state,
                editedCampaign: action.payload,
                loadingCampaign: false
            };

        case CREATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                editedCampaign: Object.assign({}, emptyCampaign)
            };

        case UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state
            };

        case UPDATE_CAMPAIGN_FIELD:
            let editedCampaign = Object.assign({}, state.editedCampaign);
            editedCampaign[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedCampaign
            }
        case LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS:
            state.editedCampaign.assignedOffersIds = action.payload.map(offer => { return { value: offer.id, label: offer.name } });
            let cmp = Object.assign({}, state.editedCampaign);
            return {
                ...state,
                editedCampaignOffers: action.payload,
                editedCampaign: cmp
            }

        case ASSIGN_OFFER_TO_CAMPAIGN:
            let edited = Object.assign({}, state.editedCampaign);
            edited.assignedOffersIds = action.payload;
            return {
                ...state,
                editedCampaign: edited
            }

        case SORT_CAMPAIGNS:
            state.campaignsSortOptions.direction = !state.campaignsSortOptions.direction;
            state.campaignsSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                campaigns: sortByField(state.campaignsSortOptions.field, state.campaignsSortOptions.direction, state.campaigns),
                campaignsSortOptions: Object.assign({}, state.campaignsSortOptions)
            }

        default:
            return state;
    }
}
