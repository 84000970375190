import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './lib/datepicker.css';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";

import Campaigns from './containers/campaigns';
import CampaignEdit from './containers/campaigns/edit';

import Offers from './containers/offers';
import OfferEdit from './containers/offers/edit';

import Sites from './containers/sites';
import SiteEdit from './containers/sites/edit';

//import Reports from './containers/reports/publisher';
import PublisherReports from './containers/reports/publisher/index';
import AdvertiserReports from './containers/reports/advertiser/index';
import AdminReports from './containers/reports/admin/index';

import FileUpload from '../src/containers/uploader/FileUpload';

import MyAccountEdit from './containers/accounts/edit';

import { Provider } from "react-redux";
import configureStore from "./store";
import Auth from './containers/auth';
import Callback from './containers/auth/callback';
import Menu from './components/menu/menu';
import {withRouter} from 'react-router-dom';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route path="/callback">
                    <Callback />
                </Route>
            </Switch>
            <Menu>
                <Switch>
                    <Route path="/campaigns/create">
                        <Auth />
                        <CampaignEdit />
                    </Route>

                    <Route path="/campaigns">
                        <Auth />
                        <Campaigns />
                    </Route>

                    <Route path="/campaign/:id">
                        <Auth />
                        <CampaignEdit />
                    </Route>

                    <Route path="/offers/create">
                        <Auth />
                        <OfferEdit />
                    </Route>

                    <Route path="/offers">
                        <Auth />
                        <Offers />
                    </Route>

                    <Route path="/offer/:id">
                        <Auth />
                        <OfferEdit />
                    </Route>


                    <Route path="/site/:id">
                        <Auth />
                        <SiteEdit />
                    </Route>

                    <Route path="/sites/create">
                        <Auth />
                        <SiteEdit />
                    </Route>

                    <Route path="/sites">
                        <Auth />
                        <Sites />
                    </Route>

                    <Route path="/site/:id">
                        <Auth />
                        <SiteEdit />
                    </Route>

                    <Route path="/publisher_reports">
                        <Auth />
                        <PublisherReports />
                    </Route>

                    <Route path="/advertiser_reports">
                        <Auth />
                        <AdvertiserReports />
                    </Route>

                    <Route path="/admin_reports">
                        <Auth />
                        <AdminReports />
                    </Route>

                    <Route path="/account">
                        <Auth />
                        <MyAccountEdit />
                    </Route>

                    <Route path="/uploader">
                        <Auth />
                        <FileUpload />
                    </Route>

                    <Route exact path="/" component={() => <Redirect to='/campaigns' />} />
                </Switch>
            </Menu>
        </Router>
    </Provider>,
    document.getElementById('root')
);

/*
REMOVED ROUTE:
<Route path="/reports">
    <Auth />
    <Reports />
</Route>
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
