import React from 'react';
import './offers.css';
import AppHeader from '../../components/header/header';
import { connect } from "react-redux";
import { OffersState } from '../../store/offers/types';
import { AppState } from '../../store';
import { updateOfferField, loadOfferById, saveOffer, removeOfferById, loadCategories, addField, removeField, updateField, removeImage, emptyOffer } from '../../store/offers/actions';
import { withRouter } from 'react-router';
import { HashLoader } from "react-spinners";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import states from '../../config/states.json';
import Select from "@mui/material/Select";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    ListItemText,
    MenuItem,
    TextField,
    Typography,
  } from "@mui/material";
import StickySaveBar from '../../components/stickySaveBar/stickySaveBar';
import { compress } from '../../lib/images';
import { SystemState } from '../../store/system/types';
import { AccountsState } from '../../store/accounts/types';
import { SitesState } from '../../store/sites/types';
import { loadSites } from '../../store/sites/actions';

const spinnerStyle = `
    margin: 100px auto;
`

interface OfferEditInterfaceProps {
    offers: OffersState,
    system: SystemState,
    accounts: AccountsState,
    sites: SitesState,
    updateOfferField: typeof updateOfferField
    loadOfferById: typeof loadOfferById
    saveOffer: typeof saveOffer
    removeOffer: typeof removeOfferById
    loadCategories: typeof loadCategories
    addField: typeof addField
    removeField: typeof removeField
    updateField: typeof updateField
    removeImage: typeof removeImage
    emptyOffer: typeof emptyOffer
    match: {
        params: {
            id: number
        }
    },
    history: any,
    loadSites: typeof loadSites
}

const mobileDesktopOptions = ['mobile', 'desktop', 'both'];

class OfferEdit extends React.Component<OfferEditInterfaceProps> {

    public componentDidMount(): void {
        this.props.loadCategories();
        this.props.loadSites();
        if (this.props.match.params.id) {
            this.props.loadOfferById(this.props.match.params.id);
        } else {
            this.props.emptyOffer()
        }
    }

    private handleChange(field: string, value: any): void {
        if (field === 'categoryId' || field === 'mobileDesktop') {
            value = value.value;
        }
        if (field === 'excludeStates') {
            if (value === null) {
                value = [];
            } else {
                value = value.map(val => val.value);
            }
        }

        if (field === 'excludeClients') {
            if (value === null) {
                value = [];
            } else {
                value = value.map(val => val.value);
            }
        }

        if (field === 'sites') {
            if (value === null) {
                value = [];
            } else {
                value = value.map(val => val.value);
            }
        }

        this.props.updateOfferField(field, value);
    }

    private saveImage(e) {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = event => {
                if (event.target !== null && typeof (event.target.result) === 'string') {
                    const image = new Image();
                    image.src = event.target.result;
                    image.onload = () => {
                        let compressed = compress(image, 1, 128, 'png');
                        if (compressed) {
                            this.props.offers.editedOffer.image = compressed.src;
                        }
                    }
                }
            }
            reader.readAsDataURL(e.target.files[0])
        }
    }

    private handleSubmit(event): void {
        event.preventDefault();
        this.props.saveOffer(this.props.offers.editedOffer, this.props.history);
    }

    private remove(id: any): void {
        if (window.confirm('Are you sure you want to delete the Offer?'))
            this.props.removeOffer(id, this.props.history);
    }

    private renderField(field, index): JSX.Element {
        return (
            <li key={`field_${index}`}>
                <button onClick={(event) => { event.preventDefault(); this.props.removeField(index) }} className="action__button field__remove">-</button>

                <div>
                    <label>Field name</label>
                    <div>
                        <input required placeholder="..." type="text" onChange={event => this.props.updateField('name', event.target.value, index)} value={field.name} />
                    </div>
                </div>

                <div>
                    <label>Rename</label>
                    <div>
                        <input placeholder="Rename..." type="text" onChange={event => this.props.updateField('rename', event.target.value, index)} value={field.rename !== null ? field.rename : ''} />
                    </div>
                </div>

                <div>
                    <label>Hardcoded value</label>
                    <div>
                        <input placeholder="Hardcoded value..." onChange={event => this.props.updateField('hardcodedValue', event.target.value, index)} type="text" value={field.hardcodedValue !== null ? field.hardcodedValue : ''} />
                    </div>
                </div>

                {this.props.system.isAdmin && (field.hardcodedValue === null || field.hardcodedValue.length === 0) ?
                    <div>
                        <label>
                            Required
                        </label>
                        <label className="checkbox">
                            <input type="checkbox" onChange={event => this.props.updateField('required', !field.required, index)} checked={field.required} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    : null}
            </li>
        )
    }

    public render(): JSX.Element {
        return (
            <div>
                <AppHeader></AppHeader>
                <HashLoader
                    //css={spinnerStyle}
                    size={100}
                    color={"#297DAC"}
                    loading={this.props.offers.loadingOffer} />
                {!this.props.offers.loadingOffer ?
                    <form className="standard__form" onSubmit={this.handleSubmit.bind(this)}>
                        <StickySaveBar />
                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 style={{ padding: '0 0 10px 0' }} className="section__name">Offer - {this.props.offers.editedOffer.title}</h1>
                                {this.props.offers.editedOffer.id ? <a href="#" className="remove__icon" onClick={() => this.remove(this.props.offers.editedOffer.id)}><img height="30" alt="" src="/assets/remove.png" /></a> : null}
                            </div>

                            <div>
                                <div className="flexBox">
                                    <div>
                                        <label>Title</label>
                                        <div>
                                            <input required type="text" value={this.props.offers.editedOffer.title} onChange={event => this.handleChange('title', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label>Offer Nick Name</label>
                                        <div>
                                            <input required type="text" value={this.props.offers.editedOffer.name} onChange={event => this.handleChange('name', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label>Offered By</label>
                                        <div>
                                            <input required type="text" value={this.props.offers.editedOffer.offeredBy} onChange={event => this.handleChange('offeredBy', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label>Category</label>
                                        <div>
                                            <Dropdown options={this.props.offers.categories.map(obj => { return { label: obj.name, value: obj.id.toString() } }) as any} onChange={status => this.handleChange('categoryId', status)} value={this.props.offers.editedOffer.categoryId.toString()} placeholder="Select category" />
                                        </div>
                                    </div>

                                    <div>
                                        <label>Image</label>
                                        <div>
                                            {(this.props.offers.editedOffer.image !== null) ?
                                                <div>
                                                    <img alt="" src={this.props.offers.editedOffer.image} />
                                                    <span className="remove__image" onClick={this.props.removeImage}>Remove</span>
                                                </div>
                                                : null}
                                            <br />
                                            <input type="file" onChange={event => this.saveImage(event)} />

                                        </div>
                                    </div>

                                    <div>
                                        <label>Privacy Policy URL</label>
                                        <div>
                                            <input type="text" value={this.props.offers.editedOffer.privacyPolicyUrl} onChange={event => this.handleChange('privacyPolicyUrl', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label>
                                            Enabled
                                        </label>
                                        <label className="checkbox">
                                            <input type="checkbox" onChange={event => this.handleChange('enabled', !this.props.offers.editedOffer.enabled)} checked={this.props.offers.editedOffer.enabled} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    {this.props.system.isAdmin ?
                                        <div>
                                            <label>
                                                Internal
                                            </label>
                                            <label className="checkbox">
                                                <input type="checkbox" onChange={event => this.handleChange('internal', !this.props.offers.editedOffer.internal)} checked={this.props.offers.editedOffer.internal} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        : ''}
                                    {this.props.system.isAdmin && this.props.offers.editedOffer.internal ?
                                        <div>
                                            <label>Sites</label>
                                            <div className="select">
                                                <Select
                                                    value={this.props.offers.editedOffer?.sites?.map(value => {
                                                        for (let site of this.props.sites.sites) {
                                                            if (site.id === value) {
                                                                return {
                                                                    label: site.name,
                                                                    value: value
                                                                }
                                                            }
                                                        }
                                                    })}
                                                    onChange={(value) => this.handleChange('sites', value)}
                                                    multiple
                                                    /*options={this.props.sites.sites.map(site => ({
                                                        label: site.name,
                                                        value: site.id
                                                    }))}*/
                                                >
                                                    {this.props.sites.sites.map((option) => (
                                                        <MenuItem key={option.name} value={option.id}>
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        : ''}

                                    <div>
                                        <label>Description</label>
                                        <div>
                                            <textarea value={this.props.offers.editedOffer.description} onChange={event => this.handleChange('description', event.target.value)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 style={{ padding: '0 0 10px 0' }} className="section__name">Leads</h1>
                            </div>

                            <div>
                                <div className="flexBox">
                                    <div>
                                        <label>Payout per Lead [USD]</label>
                                        <div>
                                            <input required step="any" type="number" value={this.props.offers.editedOffer.payoutLead} onChange={event => this.handleChange('payoutLead', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label className="required-field">% to Publisher</label>
                                        <div>
                                            <input required step="0.01" max="1" min="0" type="number" value={this.props.offers.editedOffer.percentToPublisher} onChange={event => this.handleChange('percentToPublisher', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label>Daily Lead Cap</label>
                                        <div>
                                            <input step="any" required type="number" value={this.props.offers.editedOffer.dailyLeadCap} onChange={event => this.handleChange('dailyLeadCap', event.target.value)} />
                                            {(this.props.offers.editedOffer.dailyLeadCap > 0 && this.props.offers.editedOffer.payoutLead > 0) ?
                                                <small className="hint">Max daily cost: ${this.props.offers.editedOffer.dailyLeadCap * this.props.offers.editedOffer.payoutLead}</small>
                                                : null}
                                        </div>
                                    </div>

                                    <div>
                                        <label>Total Leads Ordered</label>
                                        <div>
                                            <input step="any" required type="number" value={this.props.offers.editedOffer.totalLeadAmount} onChange={event => this.handleChange('totalLeadAmount', event.target.value)} />
                                            {(this.props.offers.editedOffer.totalLeadAmount > 0 && this.props.offers.editedOffer.totalLeadAmount > 0) ?
                                                <small className="hint">Max cost: ${this.props.offers.editedOffer.totalLeadAmount * this.props.offers.editedOffer.payoutLead}</small>
                                                : null}
                                        </div>
                                    </div>

                                    <div>
                                        <label>Total Budget</label>
                                        <div>
                                            <input step={0.01} min={0} required type="number" value={this.props.offers.editedOffer.totalBudget} onChange={event => this.handleChange('totalBudget', event.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 style={{ padding: '0 0 10px 0' }} className="section__name">Other Traffic Options</h1>
                            </div>

                            <div>
                                <div className="flexBox">
                                    <div>
                                        <label>Live URL</label>
                                        <div>
                                            <input type="text" value={this.props.offers.editedOffer.liveUrl} onChange={event => this.handleChange('liveUrl', event.target.value)} />
                                        </div>
                                    </div>
                                    {/*
                                <div>
                                    <label>Live Cap</label>
                                    <div>
                                        <input step="100" type="number" value={this.props.offers.editedOffer.liveCap} onChange={event => this.handleChange('liveCap', event.target.value)} />
                                    </div>
                                </div> */}

                                    <div>
                                        <label>Live Payout [USD]</label>
                                        <div>
                                            <input step="any" type="number" value={this.props.offers.editedOffer.livePayout} onChange={event => this.handleChange('livePayout', event.target.value)} />
                                        </div>
                                    </div>

                                    <div>
                                        <label>
                                            Offer Type
                                        </label>
                                        <div className="select">
                                            <Select
                                                value={this.props.offers.editedOffer.offerType}
                                                onChange={(value) => this.handleChange('offerType', value)}
                                                /*options={[{
                                                    label: 'Coreg',
                                                    value: 'coreg'
                                                }, {
                                                    label: 'Coop',
                                                    value: 'coop'
                                                }, {
                                                    label: 'Both',
                                                    value: 'both'
                                                }]}*/
                                                >
                                                
                                                <MenuItem key={'Coreg'} value={'coreg'}>
                                                    <ListItemText primary={'Coreg'} />
                                                </MenuItem>
                                                <MenuItem key={'Coop'} value={'coop'}>
                                                    <ListItemText primary={'Coop'} />
                                                </MenuItem>
                                                <MenuItem key={'Both'} value={'both'}>
                                                    <ListItemText primary={'Both'} />
                                                </MenuItem>
                                                
                                            </Select>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Coop payout</label>
                                        <div>
                                            <input step="any" type="number" value={this.props.offers.editedOffer.coopPayout} onChange={event => this.handleChange('coopPayout', event.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 style={{ padding: '0 0 10px 0' }} className="section__name">Targeting</h1>
                            </div>

                            <div className="flexBox">
                                <div>
                                    <label>Mobile / Desktop</label>
                                    <div>
                                        <Dropdown options={mobileDesktopOptions} onChange={status => this.handleChange('mobileDesktop', status)} value={this.props.offers.editedOffer.mobileDesktop} placeholder="Select category" />
                                    </div>
                                </div>

                                <div>
                                    <label>
                                        Pre Check?
                                    </label>
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={event => this.handleChange('preCheck', !this.props.offers.editedOffer.preCheck)} checked={this.props.offers.editedOffer.preCheck} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        Uniques Only?
                                    </label>
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={event => this.handleChange('uniquesOnly', !this.props.offers.editedOffer.uniquesOnly)} checked={this.props.offers.editedOffer.uniquesOnly} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        USA Only?
                                    </label>
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={event => this.handleChange('usaOnly', !this.props.offers.editedOffer.usaOnly)} checked={this.props.offers.editedOffer.usaOnly} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label>Exclude States</label>
                                    <div className="select">
                                        <Select
                                            value={this.props.offers.editedOffer.excludeStates.map(value => {
                                                for (let state of states) {
                                                    if (state.value === value) {
                                                        return {
                                                            label: state.label,
                                                            value: value
                                                        }
                                                    }
                                                }
                                            })}
                                            onChange={(value) => this.handleChange('excludeStates', value)}
                                            multiple
                                            /*options={states}*/
                                            >
                                            {states.map((option) => (
                                                <MenuItem key={option.label} value={option.value}>
                                                    <ListItemText primary={option.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    {this.props.system.isAdmin && this.props.offers.editedOffer.excludeClients ?
                                        <div>
                                            <label>Exclude Clients</label>
                                            <div className="select">
                                                <Select
                                                    value={this.props.offers.editedOffer.excludeClients.map(value => {
                                                        for (let client of this.props.accounts.accounts) {
                                                            if (client.id === value) {
                                                                return {
                                                                    label: client.name,
                                                                    value: value
                                                                }
                                                            }
                                                        }
                                                    })}
                                                    onChange={(value) => this.handleChange('excludeClients', value)}
                                                    multiple
                                                    /*options={this.props.accounts.accounts.map(acc => ({
                                                        label: acc.name,
                                                        value: acc.id
                                                    }))}*/
                                                    >
                                                    {this.props.accounts.accounts.map((option) => (
                                                        <MenuItem key={option.name} value={option.id}>
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </section>

                        <section style={{
                            padding: '20px 20px 20px'
                        }}>
                            <div className="page__title">
                                <h1 style={{ padding: '0 0 10px 0' }} className="section__name">Fields <button onClick={(event) => { event.preventDefault(); this.props.addField() }} className="action__button">+</button></h1>
                            </div>

                            <div>
                                <div>
                                    <label>Webhook URL</label>
                                    <div>
                                        <input type="text" value={this.props.offers.editedOffer.webhookUrl} onChange={event => this.handleChange('webhookUrl', event.target.value)} />
                                    </div>
                                </div>
                                <div>
                                    <label>Webhook Method</label>
                                    <div>
                                        <select value={this.props.offers.editedOffer.webhookMethod} onChange={event => this.handleChange('webhookMethod', event.target.value)}>
                                            <option value={"GET"}>GET</option>
                                            <option value={"POST"}>POST</option>
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label>Headers</label>
                                    <div>
                                        <textarea value={this.props.offers.editedOffer.header} onChange={event => this.handleChange('header', event.target.value)} />
                                    </div>
                                </div>

                                <ul className="fields">
                                    {this.props.offers.editedOffer.fields.map((field, i) => this.renderField(field, i))}
                                </ul>
                            </div>
                        </section>
                    </form>
                    : null}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateOfferField: (field, value) => dispatch(updateOfferField(field, value)),
        loadOfferById: id => dispatch(loadOfferById(id)),
        saveOffer: (offer, history) => dispatch(saveOffer(offer, history)),
        removeOffer: (id, history) => dispatch(removeOfferById(id, history)),
        loadCategories: () => dispatch(loadCategories()),
        addField: () => dispatch(addField()),
        removeField: index => dispatch(removeField(index)),
        updateField: (field, value, index) => dispatch(updateField(field, value, index)),
        removeImage: () => dispatch(removeImage()),
        emptyOffer: () => dispatch(emptyOffer()),
        loadSites: () => dispatch(loadSites()),
    }
}

const mapStateToProps = (state: AppState) => ({
    offers: state.offers,
    system: state.system,
    accounts: state.accounts,
    sites: state.sites,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OfferEdit)
);
