import React from 'react';
import './advertiser.css';
import AppHeader from '../../../components/header/header';
import DataTable from '../../../components/dataTable';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink, CSVDownload } from "react-csv";

import { connect } from "react-redux";
import { OffersState } from '../../../store/offers/types';
import { AppState } from '../../../store';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { HashLoader } from "react-spinners";
import { AdvertiserReportsState } from '../../../store/reports/advertiser_reports/types';
import { loadAdvertiserReports, sortAdvertiserReports, loadAdvertiserOffers, addOffer, changeType, loadSites, filterByDate, filterByDateType, resetAdvertiserState } from '../../../store/reports/advertiser_reports/actions';
import { SystemState } from '../../../store/system/types';
import { SitesState } from '../../../store/sites/types';

const spinnerStyle = `
    margin: 100px auto;
`;

const dateFilters = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 3 days', value: 'last 3 days' },
    { label: 'Last 7 days', value: 'last 7 days' },
    { label: 'Last 30 days', value: 'last 30 days' },
    { label: 'All Time', value: 'all Time' },
    { label: 'Custom', value: 'custom' }
];

const optinCols = [{
    fieldName: "Email Address",
    fieldValue: "email",
    fieldType: 'string'
}, {
    fieldName: "Site Id",
    fieldValue: "siteid",
    fieldType: 'string'
}, {
    fieldName: "Offer",
    fieldValue: "offername",
    fieldType: 'string'
}, {
    fieldName: "Timestamp",
    fieldValue: "timestamp",
    fieldType: 'datetime'
}, {
    fieldName: "Payout",
    fieldValue: "payout_lead",
    fieldType: 'string'
}, {
    fieldName: "Live Payout",
    fieldValue: "live_payout",
    fieldType: 'double'
},];

const countCols = [{
    fieldName: "Offer",
    fieldValue: "offername",
    fieldType: 'string'
}, {
    fieldName: "Optins",
    fieldValue: "optin_count",
    fieldType: 'int'
}, {
    fieldName: "Impressions",
    fieldValue: "impression_count",
    fieldType: 'int'
}, {
    fieldName: "Optin %",
    fieldValue: "optin_percentage",
    fieldType: 'decimal'
}, {
    fieldName: "Total Payout",
    fieldValue: "payout_total",
    fieldType: 'decimal'
}];

interface AdvertiserReportsInterfaceProps {
    advertiserReports: AdvertiserReportsState
    system: SystemState
    sites: SitesState
    offers: OffersState
    loadSites: typeof loadSites
    loadAdvertiserOffers: typeof loadAdvertiserOffers
    loadAdvertiserReports: typeof loadAdvertiserReports
    sortAdvertiserReports: typeof sortAdvertiserReports
    addOffer: typeof addOffer
    changeType: typeof changeType
    filterByDate: typeof filterByDate
    filterByDateType: typeof filterByDateType
    resetAdvertiserState: typeof resetAdvertiserState
}

class AdvertiserReport extends React.Component<AdvertiserReportsInterfaceProps & RouteComponentProps> {

    public componentDidMount(): void {
        //this.loadPage();
        this.props.loadAdvertiserOffers();
        this.props.loadSites();
        this.props.resetAdvertiserState();
    }

    private async loadReports(type, dateType, offersArray) {

        const offers = this.props.advertiserReports.chosenOfferCodes.length == 0 ? String(this.props.offers.offers.map(offer => { return offer.uuid })) : String(this.props.advertiserReports.chosenOfferCodes);
        console.log(offers);

        this.props.loadAdvertiserReports(
            type == null ? this.props.advertiserReports.reportType : type,
            offersArray == null ? offers : String(offersArray),
            this.props.advertiserReports.filterByDate,
            dateType == null ? this.props.advertiserReports.filterByDateType : dateType,
            this.props.system.selectedClient?.leadStatusReporting,
            this.props.system.selectedClient?.smartRecognition == 1 ? this.props.system.selectedClient.id : null
        );
    }


    handleChange(name: string, value: any) {
        console.log(name);
        console.log(value);
        if (name == 'chosenOfferCodes') {
            if (value === null) {
                value = [];
            } else {
                value = value.map(val => val.value);
                //const array = this.props.advertiserReports.chosenSiteCodes;
                //array.push(value.value);
                this.props.addOffer(value);
                this.loadReports(this.props.advertiserReports.reportType == 4 ? 0 : null, null, value);
            }
        }
        if (name == 'changeType') {
            this.props.changeType(value);
            this.loadReports(value, null, null);
        }
        if (name == 'filterDateType') {
            console.log(value);
            this.props.filterByDateType(value);
            this.loadReports(this.props.advertiserReports.reportType == 4 ? 0 : null, value, null);
        }
        if (name == 'filterDateTo') {
            console.log(value);
            const filter = { to: value, from: this.props.advertiserReports.filterByDate.from }
            this.props.filterByDate(filter);
        }
        if (name == 'filterDateFrom') {
            console.log(value);
            const filter = { to: this.props.advertiserReports.filterByDate.to, from: value }
            this.props.filterByDate(filter);
        }
    }

    changeColor(val: string) {
        document!.getElementById("opt")!.style.backgroundColor = "#297dac";
        document!.getElementById("opt")!.style.color = "white";
        document!.getElementById("opt")!.style.border = "0";
        document!.getElementById("cnt")!.style.backgroundColor = "#297dac";
        document!.getElementById("cnt")!.style.color = "white";
        document!.getElementById("cnt")!.style.border = "0";

        document!.getElementById(val)!.style.backgroundColor = "white";
        document!.getElementById(val)!.style.color = "black";
        document!.getElementById(val)!.style.border = "solid 1px #297dac";
    }

    public render(): JSX.Element {
        console.log(this);

        console.log(this.props.advertiserReports.reports);

        return (
            <div>


                <AppHeader></AppHeader>
                <div>
                    <button className='tabs' id="opt" onClick={() => this.handleChange('changeType', 0)}>Optins</button>

                    <button className='tabs' id="cnt" onClick={() => this.handleChange('changeType', 1)}>Counts</button>

                    <CSVLink className='downloadbutton' filename="SmartbrandsReport" data={this.props.advertiserReports.reports}>
                        <span className='download'>
                            <svg className='download' focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                            </svg>
                            Export to CSV
                        </span>
                    </CSVLink>
                </div>

                <div>
                    Filter By Date
                    <Select
                        //label="Date"
                        value={this.props.advertiserReports.filterByDateType}
                        onChange={event =>
                            this.handleChange('filterDateType', event)
                        }
                        options={dateFilters}
                    />

                    {this.props.advertiserReports.filterByDateType.value === 'custom' ? (
                        <>
                            From
                            <DatePicker
                                label="From"
                                selected={this.props.advertiserReports.filterByDate.from}
                                onChange={event =>
                                    this.handleChange('filterDateFrom', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                            To
                            <DatePicker
                                label="To"
                                selected={this.props.advertiserReports.filterByDate.to}
                                onChange={event =>
                                    this.handleChange('filterDateTo', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                        </>
                    ) : null}
                </div>
                {this.props.advertiserReports.reportType == 0 ?
                    <>
                        <div>
                            Filter By Offer
                            <Select
                                value={this.props.advertiserReports.chosenOfferCodes.map(value => {
                                    console.log(value);
                                    for (let offer of this.props.offers.offers) {
                                        console.log(offer);
                                        if (offer.uuid == value) {
                                            return {
                                                label: offer.name,
                                                value: value
                                            }
                                        }
                                    }
                                })}
                                onChange={(value) => this.handleChange('chosenOfferCodes', value)}
                                isMulti={true}
                                options={this.props.offers.offers.map(offer => {
                                    return {
                                        value: offer.uuid,
                                        label: offer.name
                                    }
                                })}
                            />
                        </div>
                        <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.advertiserReports.loading} />
                        {!this.props.advertiserReports.loading ?
                            <div>
                                Optins
                                <DataTable orderField={this.props.advertiserReports.reportSortOptions.field} orderDirection={this.props.advertiserReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortAdvertiserReports(col)} emptyText="No Reporting available yet" cols={optinCols} rows={this.props.advertiserReports.reports}></DataTable>
                            </div>
                            : 'LOADING'}
                    </> : this.props.advertiserReports.reportType == 1 ?
                        <>
                            <div>
                                Filter By Offer
                                <Select
                                    value={this.props.advertiserReports.chosenOfferCodes.map(value => {
                                        console.log(value);
                                        for (let offer of this.props.offers.offers) {
                                            console.log(offer);
                                            if (offer.uuid == value) {
                                                return {
                                                    label: offer.name,
                                                    value: value
                                                }
                                            }
                                        }
                                    })}
                                    onChange={(value) => this.handleChange('chosenOfferCodes', value)}
                                    isMulti={true}
                                    options={this.props.offers.offers.map(offer => {
                                        return {
                                            value: offer.uuid,
                                            label: offer.name
                                        }
                                    })}
                                />
                            </div>
                            <HashLoader
                                //css={spinnerStyle}
                                size={100}
                                color={"#297DAC"}
                                loading={this.props.advertiserReports.loading} />
                            {!this.props.advertiserReports.loading ?
                                <div>
                                    Optins
                                    <DataTable orderField={this.props.advertiserReports.reportSortOptions.field} orderDirection={this.props.advertiserReports.reportSortOptions.direction} colClickAction={(col) => this.props.sortAdvertiserReports(col)} emptyText="No Reporting available yet" cols={countCols} rows={this.props.advertiserReports.reports}></DataTable>
                                </div>
                                : 'LOADING'}
                        </> : null
                }


            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    advertiserReports: state.advertiserReports,
    sites: state.sites,
    system: state.system,
    offers: state.offers,
    loadAdvertiserReports: loadAdvertiserReports,
    sortAdvertiserReports: sortAdvertiserReports,
    loadAdvertiserOffers: loadAdvertiserOffers,
    addOffer: addOffer,
    changeType: changeType,
    loadSites: loadSites,
    filterByDate: filterByDate,
    filterByDateType: filterByDateType,
    resetAdvertiserState: resetAdvertiserState
});

export default withRouter(
    connect(mapStateToProps, {
        loadAdvertiserReports,
        sortAdvertiserReports,
        loadAdvertiserOffers,
        addOffer,
        changeType,
        loadSites,
        filterByDate,
        filterByDateType,
        resetAdvertiserState
    })(AdvertiserReport)
);
