import { MENU_TOGGLE, CLIENT_MENU_TOGGLE, SELECT_CLIENT, CHECK_ADMIN_RIGHTS } from './types'
import { Session } from '../auth/types'
import { LOAD_ACCOUNT_BY_ID_SUCCESS } from '../accounts/types'

export const menuToggle = () => ({
  type: MENU_TOGGLE
})

export const toggleClientMenu = () => ({
  type: CLIENT_MENU_TOGGLE
})


export const selectClient = (account: any, history) => {
    history.history.push('/');
    return (dispatch: any) => {
        dispatch({
            type: SELECT_CLIENT,
            payload: null
        })

        localStorage.setItem('lastSelectedClient', JSON.stringify(account) );

        setTimeout(() => {
            dispatch({
                type: SELECT_CLIENT,
                payload: account
            })
            dispatch({
                type: LOAD_ACCOUNT_BY_ID_SUCCESS,
                payload: account
            })
        }, 0)
    }
}

export const selectInitialClient = (accounts: any) => {
    return (dispatch: any) => {
        dispatch({
            type: SELECT_CLIENT,
            payload: null
        })

        let lastSelected = localStorage.getItem('lastSelectedClient');
        if(lastSelected !== null) {
            lastSelected = JSON.parse(lastSelected);
            dispatch({
                type: SELECT_CLIENT,
                payload: lastSelected
            })

        } else {
            let session = localStorage.getItem('session');
            let decodedSession: Session = session === null ? session : JSON.parse(session);
            if(decodedSession !== null) {
                for(let account of accounts) {
                    if(account.email === decodedSession.user.email) {
                        dispatch({
                            type: SELECT_CLIENT,
                            payload: account
                        })

                        dispatch({
                            type: LOAD_ACCOUNT_BY_ID_SUCCESS,
                            payload: account
                        })
                    }
                }
            }
        }
    }
}

export const checkAdminRights = () => ({
    type: CHECK_ADMIN_RIGHTS
})