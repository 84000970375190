import {
    AccountsState,
    LOAD_ACCOUNTS_SUCCESS,
    AccountActionTypes,
    UPDATE_ACCOUNT_FIELD,
    LOAD_ACCOUNT_BY_ID_SUCCESS,
    LOAD_ACCOUNTS,
    LOAD_ACCOUNT_BY_ID,
    UPDATE_ACCOUNT_SUCCESS,
    FILTER_ACCOUNTS
  } from "./types";

  const initialState: AccountsState = {
    loading: false,
    loadingAccount: false,
    accounts: [],
    accountsFiltered: [],
    editedAccount: null
  };
  
  export function accountsReducer(
    state = initialState,
    action: AccountActionTypes
  ): AccountsState {
    switch (action.type) {
        case LOAD_ACCOUNTS:
          return {
            ...state,
            loading: true
          }
        case LOAD_ACCOUNTS_SUCCESS:
            return {
                ...state,
                loading: false,
                accounts: action.payload
            };


        case LOAD_ACCOUNT_BY_ID:
            return {
                ...state,
                loadingAccount: true
            };

        case LOAD_ACCOUNT_BY_ID_SUCCESS:
            return {
                ...state,
                editedAccount: action.payload,
                loadingAccount: false
            };

        case UPDATE_ACCOUNT_SUCCESS:
            return {
                ...state
            };

        case UPDATE_ACCOUNT_FIELD:
            let editedAccount = Object.assign({}, state.editedAccount);
            editedAccount[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedAccount
            }
        case FILTER_ACCOUNTS:
          let accountsFiltered: Array<any> = [];

          state.accounts.map(account => {
            if(account.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1) {
              accountsFiltered.push(account);
            }
          })

          return {
            ...state,
            accountsFiltered: accountsFiltered
          }
      default:
        return state;
    }
  }
  