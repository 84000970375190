import React from 'react';
import './campaigns.css';
import AppHeader from '../../components/header/header';
import DataTable from '../../components/dataTable';

import { connect } from "react-redux";
import { CampaignsState } from '../../store/campaigns/types';
import { AppState } from '../../store';
import { loadCampaigns, sortCampaigns, cloneCampaign } from '../../store/campaigns/actions';
import { withRouter } from 'react-router';
import { HashLoader } from "react-spinners";
import { NavLink as Link } from 'react-router-dom';

const spinnerStyle = `
    margin: 100px auto;
`

const cols = [{
    fieldName: "Name",
    fieldValue: "name",
    fieldType: 'string'
}, {
    fieldName: "Start Date",
    fieldValue: "start",
    fieldType: 'date'
}, {
    fieldName: "End Date",
    fieldValue: "end",
    fieldType: 'date'
}, {
    fieldName: "Daily Lead Cap",
    fieldValue: "dailyLeadCap",
    fieldType: 'number'
}, {
    fieldName: "Total Lead Amount",
    fieldValue: "totalLeadAmount",
    fieldType: 'number'
}, {
    fieldName: "Assigned Offers",
    fieldValue: "assignedOffers",
    fieldType: 'string'
}, {
    fieldName: "Status",
    fieldValue: "status",
    fieldType: 'string'
}, {
    fieldName: "Clone",
    fieldValue: "Clone",
    fieldType: 'button'
}]

interface CampaignsInterfaceProps {
    campaigns: CampaignsState,
    loadCampaigns: typeof loadCampaigns
    sortCampaigns: typeof sortCampaigns
    history: any
    cloneCampaign: Function
}

class Campaigns extends React.Component<CampaignsInterfaceProps> {

    public componentDidMount():void {
        this.props.loadCampaigns();
    }

    public edit(id): void {
        this.props.history.push(`/campaign/${id}`);
    }

    public cloneCampaign(value): void {
        this.props.cloneCampaign(value, this.props.history)
    }

    public sort(col): void {
       this.props.sortCampaigns(col);
    }

    public render(): JSX.Element {
        return (
            <div>
                <AppHeader></AppHeader>
                <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.campaigns.loading} />
                {!this.props.campaigns.loading ?
                    <section>
                        <div className="page__title">
                            <h1 className="section__name">Campaigns</h1>
                            <Link className="create__btn" to="/campaigns/create">Create Campaign</Link>
                        </div>

                        <DataTable orderField={this.props.campaigns.campaignsSortOptions.field} orderDirection={this.props.campaigns.campaignsSortOptions.direction} emptyText="No Campaign created yet" colClickAction={this.sort.bind(this)} rowClickAction={this.edit.bind(this)} cols={cols} rows={this.props.campaigns.campaigns} buttonAction={this.cloneCampaign.bind(this)}></DataTable>
                    </section>
                : null}
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    campaigns: state.campaigns,
    loadCampaigns: loadCampaigns,
    sortCampaigns: sortCampaigns,
});

export default withRouter(
    connect(mapStateToProps, {
        loadCampaigns,
        sortCampaigns,
        cloneCampaign
    })(Campaigns)
);
