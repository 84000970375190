import { LOAD_CAMPAIGNS_SUCCESS, UPDATE_CAMPAIGN_FIELD, LOAD_CAMPAIGN_BY_ID, LOAD_CAMPAIGN_BY_ID_SUCCESS, LOAD_CAMPAIGNS, UPDATE_CAMPAIGN_SUCCESS, CREATE_CAMPAIGN_SUCCESS, Campaign, REMOVE_CAMPAIGN_BY_ID, REMOVE_CAMPAIGN_BY_ID_SUCCESS, LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS, ASSIGN_OFFER_TO_CAMPAIGN, ASSIGN_OFFER_TO_CAMPAIGN_SAVE, SORT_CAMPAIGNS, CLONE_CAMPAIGN_SUCCESS } from "./types";
import axios from '../../lib/axios';
import config from '../../config/app-config.json';
import store from '../../store';

export const loadCampaigns = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_CAMPAIGNS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/campaigns`).then((response) => {
            dispatch({
                type: LOAD_CAMPAIGNS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const sortCampaigns = col => {
    return dispatch => dispatch({
        type: SORT_CAMPAIGNS,
        payload: col
    })
}

export const updateCampaignField = (field, value) => {
    return {
        type: UPDATE_CAMPAIGN_FIELD,
        payload: {field, value}
    }
}

export const loadCampaignById = id => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_CAMPAIGN_BY_ID,
            payload: null
        })
        axios.get(`${config.serviceUrl}/campaigns/${id}`).then((response) => {
            dispatch({
                type: LOAD_CAMPAIGN_BY_ID_SUCCESS,
                payload: response.data.data[0]
            })
            axios.get(`${config.serviceUrl}/campaigns/${id}/offers`).then((offers) => {
                dispatch({
                    type: LOAD_OFFERS_BY_CAMPAIGN_ID_SUCCESS,
                    payload: offers.data.data
                })
            })
        })
    }
}

export const removeCampaignById = (id, history) => {
    return (dispatch: any) => {
        dispatch({
            type: REMOVE_CAMPAIGN_BY_ID,
            payload: null
        })
        axios.delete(`${config.serviceUrl}/campaigns/${id}`).then((response) => {
            dispatch({
                type: REMOVE_CAMPAIGN_BY_ID_SUCCESS
            })

            history.push('/campaigns');
        })
    }
}

export const saveCampaign = (campaign: Campaign, history: any) => {
    return (dispatch: any) => {
        if(campaign.id) {
            axios.put(`${config.serviceUrl}/campaigns/${campaign.id}`, campaign).then((response) => {
                history.push(`/`);
                history.push(`/campaign/${campaign.id}`);
                dispatch({
                    type: UPDATE_CAMPAIGN_SUCCESS,
                    payload: response
                });

                dispatch(saveAssignment() )
            })
        } else {
            axios.post(`${config.serviceUrl}/campaigns`, campaign).then((response) => {
                history.push(`/campaign/${response.data.data.id}`);
                dispatch({
                    type: CREATE_CAMPAIGN_SUCCESS,
                    payload: response
                })

                dispatch(saveAssignment() )
                dispatch(loadCampaignById(response.data.data.id) );
            })
        }
    }
}

export const assignOfferToCampaign = option => {
    return (dispatch) => {
        dispatch({
            type: ASSIGN_OFFER_TO_CAMPAIGN,
            payload: option
        })
    }
}

export const saveAssignment = () => {
    return (dispatch: any, getState) => {
        if(getState().campaigns.editedCampaign.assignedOffersIds !== null) {
            let assigned = getState().campaigns.editedCampaign.assignedOffersIds.map(value => {
                return value.value;
            });
            axios.put(`${config.serviceUrl}/campaigns/${getState().campaigns.editedCampaign.id}/offers`, {offerIds: assigned})

        } else {
            axios.put(`${config.serviceUrl}/campaigns/${getState().campaigns.editedCampaign.id}/offers`, {offerIds: []})
        }
    }
}

export const emptyCampaign = () => ({
    type: CREATE_CAMPAIGN_SUCCESS,
    payload: null
})

export const cloneCampaign = (campaignId: any, history: any) => {
    return (dispatch: any) => {
        if(campaignId) {
            axios.put(`${config.serviceUrl}/campaigns/${campaignId}/clone`).then((response) => {
                const payload = response.data.data[0];
                const cloneCampaignId = payload.id;
                history.push(`/`);
                history.push(`/campaign/${cloneCampaignId}`);
                dispatch({
                    type: CLONE_CAMPAIGN_SUCCESS,
                    payload: response
                });
            }).catch(err => {
                console.error("error occured during execution", err?.message)
            })
        } 
    }
}