
import React from 'react'
import { connect } from 'react-redux'
import cognitoUtils from '../../lib/cognitoUtils'

const mapStateToProps = (state: any) => {

  return { session: state.authReducer.session, isLoggedIn: state.authReducer.isLoggedIn }
}

export interface AuthPropsInterface {
  isLoggedIn: boolean
  session: any,
  location?: any,
  initSessionFromCallbackURI?: any
}

class Auth extends React.Component<AuthPropsInterface> {
    
  public componentDidMount () {
    if (!this.props.session || !this.props.isLoggedIn) {
      document.location.href = cognitoUtils.getCognitoSignInUri();
    }
  }

  public onSignOut = (e: any) => {
    e.preventDefault()
    cognitoUtils.signOutCognitoSession()
  }

  public render() {
    return null
  }
}

export default connect(mapStateToProps)(Auth)