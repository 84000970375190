import React from 'react';
import './offers.css';
import AppHeader from '../../components/header/header';
import DataTable from '../../components/dataTable';

import { connect } from "react-redux";
import { OffersState } from '../../store/offers/types';
import { AppState } from '../../store';
import { loadOffers, sortOffers } from '../../store/offers/actions';
import { withRouter } from 'react-router';
import { HashLoader } from "react-spinners";
import { NavLink as Link } from 'react-router-dom';

const spinnerStyle = `
    margin: 100px auto;
`

const cols = [{
    fieldName: 'Name',
    fieldValue: 'name',
    fieldType: 'string'
},{
    fieldName: "Title",
    fieldValue: "title",
    fieldType: 'string'
}, {
    fieldName: "Offered by",
    fieldValue: "offeredBy",
    fieldType: 'string'
}, {
    fieldName: "Payout Lead",
    fieldValue: "payoutLead",
    fieldType: 'number'
}, {
    fieldName: "Daily Lead Cap",
    fieldValue: "dailyLeadCap",
    fieldType: 'number'
}, {
    fieldName: "Total Lead Amount",
    fieldValue: "totalLeadAmount",
    fieldType: 'number'
}]

interface OffersInterfaceProps {
    offers: OffersState
    loadOffers: typeof loadOffers
    history: any
    sortOffers: typeof sortOffers
}

class Offers extends React.Component<OffersInterfaceProps> {

    public componentDidMount():void {
        this.props.loadOffers();
    }

    public edit(id): void {
        this.props.history.push(`/offer/${id}`);
    }

    public render(): JSX.Element {
        return (
            <div>
                <AppHeader></AppHeader>
                <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.offers.loading} />
                {!this.props.offers.loading ?
                <section>
                    <div className="page__title">
                        <h1 className="section__name">Offers</h1>
                        <Link className="create__btn" to="/offers/create">Create Offer</Link>
                    </div>

                    {this.props.offers.loading ?
                        <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.offers.loading}
                    /> :
                    <DataTable orderField={this.props.offers.offersSortOptions.field} orderDirection={this.props.offers.offersSortOptions.direction} colClickAction={(col) => this.props.sortOffers(col)} emptyText="No Offer created yet" rowClickAction={this.edit.bind(this)} cols={cols} rows={this.props.offers.offers}></DataTable>
                    }

                </section>
                : null}
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    offers: state.offers,
    loadOffers: loadOffers,
    sortOffers: sortOffers
});

export default withRouter(
    connect(mapStateToProps, {
        loadOffers,
        sortOffers
    })(Offers)
);
