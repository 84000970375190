import {
    OffersState,
    LOAD_OFFERS_SUCCESS,
    OfferActionTypes,
    UPDATE_OFFER_FIELD,
    LOAD_OFFER_BY_ID_SUCCESS,
    LOAD_OFFERS,
    LOAD_OFFERS_CAMPAIGN,
    LOAD_OFFERS_CAMPAIGN_SUCCESS,
    LOAD_ALL_OFFERS,
    LOAD_ALL_OFFERS_SUCCESS,
    LOAD_OFFER_BY_ID,
    UPDATE_OFFER_SUCCESS,
    CREATE_OFFER_SUCCESS,
    LOAD_CATEGORIES_SUCCESS,
    ADD_FIELD,
    Field,
    REMOVE_FIELD,
    UPDATE_FIELD,
    SORT_OFFERS,
    REMOVE_IMAGE
  } from "./types";
import { SORT_CAMPAIGNS } from "../campaigns/types";
import { sortByField } from "../../lib/sort";
const emptyOffer = {
title: "",
name: "",
categoryId: '',
offeredBy: "",
description: "",
image: null,
payoutLead: 10,
percentToPublisher: 0,
webhookUrl: "",
webhookMethod: "GET",
dailyLeadCap: 0,
totalLeadAmount: 0,
totalBudget: 0,
uniquesOnly: false,
preCheck: false,
mobileDesktop: 'both',
usaOnly: true,
excludeStates: [],
sites: [],
excludeClients: [],
liveUrl: '',
livePayout:	0,
liveCap: 0,
enabled: true,
internal: false,
privacyPolicyUrl: '',
header: '{}',
fields: [{
        required: true,
        name: 'email',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'fname',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'siteId',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'date',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'timestamp',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'ip',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'utm_source',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'utm_medium',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'utm_campaign',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'utm_term',
        rename: '',
        hardcodedValue: ''
    },
    {
        required: false,
        name: 'utm_content',
        rename: '',
        hardcodedValue: ''
    }],
    offerType: 'coreg',
    coopPayout: 0.00
};

export const emptyField: Field = {
    required: true,
    name: '',
    rename: '',
    hardcodedValue: '',
}

const initialSort = {
    field: 'title',
    direction: false
}
  
  const initialState: OffersState = {
    loading: false,
    loadingOffer: false,
    offers: [],
    offersSortOptions: Object.assign({}, initialSort),
    editedOffer: Object.assign({}, emptyOffer),
    categories: [],
    allOffers: [],
    availableOffers: []
  };
  
  export function offersReducer(
    state = initialState,
    action: OfferActionTypes
  ): OffersState {
    switch (action.type) {
        case LOAD_CATEGORIES_SUCCESS:
          return {
              ...state,
              categories: [...action.payload]
          };
        case LOAD_OFFERS:
          return {
            ...state,
            offers: [],
            loading: true
          }
        case LOAD_OFFERS_SUCCESS:
            return {
                ...state,
                offers: [...action.payload],
                loading: false
            };

        case LOAD_OFFERS_CAMPAIGN:
            return {
                ...state,
                availableOffers: [],
                loading: true
            }

        case LOAD_OFFERS_CAMPAIGN_SUCCESS:
            return {
                ...state,
                availableOffers: [...action.payload],
                loading: false
            }
        
        case LOAD_ALL_OFFERS:
            return {
                ...state,
                allOffers: [],
                loading: true
            }
        case LOAD_ALL_OFFERS_SUCCESS:
            return {
                ...state,
                allOffers: [...action.payload],
                loading: false
            };
              


        case LOAD_OFFER_BY_ID:
            return {
                ...state,
                loadingOffer: true
            };

        case LOAD_OFFER_BY_ID_SUCCESS:
            return {
                ...state,
                editedOffer: action.payload,
                loadingOffer: false
            };

        case CREATE_OFFER_SUCCESS:
            return {
                ...state,
                editedOffer: Object.assign({}, emptyOffer)
            };

        case UPDATE_OFFER_SUCCESS:
            return {
                ...state
            };

        case UPDATE_OFFER_FIELD:
            let editedOffer = Object.assign({}, state.editedOffer);
                editedOffer[action.payload.field] = action.payload.value;
                return {
                    ...state,
                    editedOffer
                }

        case ADD_FIELD:
          state.editedOffer.fields.push(Object.assign({}, emptyField) );
          return {
            ...state,
            editedOffer: Object.assign({}, state.editedOffer)
          }

        case REMOVE_FIELD:
            state.editedOffer.fields.splice(action.payload, 1);
            return {
              ...state,
              editedOffer: Object.assign({}, state.editedOffer)
            }
        case UPDATE_FIELD:
            state.editedOffer.fields[action.payload.index][action.payload.field] = action.payload.value;
            return {
              ...state,
              editedOffer: Object.assign({}, state.editedOffer)
            }

        case SORT_OFFERS:
            state.offersSortOptions.direction = !state.offersSortOptions.direction;
            state.offersSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                offers: sortByField(state.offersSortOptions.field, state.offersSortOptions.direction, state.offers),
                offersSortOptions: Object.assign({}, state.offersSortOptions)
            }
        case REMOVE_IMAGE:
            state.editedOffer.image = null;
            return {
                ...state,
                editedOffer: Object.assign({}, state.editedOffer)
            }
      default:
        return state;
    }
  }
  