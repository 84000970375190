import {
    LOAD_AD_REPORTS,
    LOAD_PB_REPORTS,
    LOAD_AD_REPORTS_SUCCESS,
    LOAD_PB_REPORTS_SUCCESS,
    SORT_AD_REPORTS,
    SORT_PB_REPORTS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_STATE,
} from "./types";
import axios from '../../../lib/axios';
import config from '../../../config/app-config.json';

export const loadAdminReports = (filterByDate, filterByDateType) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOAD_AD_REPORTS,
            payload: []
        })

        await axios.get(`${config.serviceUrl}/admin_reports`, {
            params: {
                    reportType: `0`,
                    filterByDateTo: `${filterByDate.to}`,
                    filterByDateFrom: `${filterByDate.from}`,
                    filterByDateType: `${filterByDateType.value}`,
                }
            }).then((response) => {
            dispatch({
                type: LOAD_AD_REPORTS_SUCCESS,
                payload: response.data
            })
        })
    }
}


export const loadAdminPublishersReports = (filterByDate, filterByDateType) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOAD_PB_REPORTS,
            payload: []
        })
        await axios.get(`${config.serviceUrl}/admin_reports`, {
            params: {
                reportType: '1',
                filterByDateTo: `${filterByDate.to}`,
                filterByDateFrom: `${filterByDate.from}`,
                filterByDateType: `${filterByDateType.value}`,
            }
        }).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_PB_REPORTS_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const loadAdminAdvertiserReports = (filterByDate, filterByDateType) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOAD_AD_REPORTS,
            payload: []
        })
        await axios.get(`${config.serviceUrl}/admin_reports`, {
            params: {
                reportType: `0`,
                filterByDateTo: `${filterByDate.to}`,
                filterByDateFrom: `${filterByDate.from}`,
                filterByDateType: `${filterByDateType.value}`,
            }
        }).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_AD_REPORTS_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const sortAdminPublisherReports = col => {
    return dispatch => dispatch({
        type: SORT_PB_REPORTS,
        payload: col
    })
}

export const sortAdminAdvertiserReports = col => {
    return dispatch => dispatch({
        type: SORT_AD_REPORTS,
        payload: col
    })
}

export const filterByDate = (value) => {
    return {
        type: FILTER_DATE,
        payload: value
    }
}

export const filterByDateType = (value) => {
    return {
        type: FILTER_DATE_TYPE,
        payload: value
    }
}

export const resetState = () => {
    return dispatch => dispatch({
        type: RESET_STATE,
        payload: []
    })
}
