import React from 'react';
import './header.css';
import { NavLink as Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { menuToggle, toggleClientMenu, selectClient } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { AppState } from '../../store';
import { filterClients } from '../../store/accounts/actions';
import { AccountsState } from '../../store/accounts/types';

interface AppHeaderInterface {
    accounts: AccountsState
    logout: typeof logout
    menuToggle: typeof menuToggle
    system: SystemState
    toggleClientMenu: typeof toggleClientMenu
    selectClient: typeof selectClient
    filterClients: typeof filterClients
}

const mapDispatchToProps = dispatch => {
    return {
      logout: () => dispatch(logout() ),
      menuToggle: () => dispatch(menuToggle() ),
      toggleClientMenu: () => dispatch(toggleClientMenu() ),
      selectClient: (account, history) => dispatch(selectClient(account, history) ),
      filterClients: e => dispatch(filterClients(e.target.value) )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    accounts: state.accounts
})

class AppHeader extends React.Component<AppHeaderInterface & RouteComponentProps> {
    componentDidMount() {

    }


    toggleClientMenu() {
        this.props.toggleClientMenu();
        this.props.filterClients({target: {value: ''}});

    }

    render() {
        return (
            <div className="app__header">
                <button onClick={this.props.menuToggle} className="app__menu-icon">
                    <img alt="" width="24" src="/assets/hamburger.png" />
                </button>

                {(this.props.system.isAdmin) ?
                    <div className={`client__switch ${this.props.system.clientsExpanded ? 'expanded' : ''}`}>
                        <button onClick={this.toggleClientMenu.bind(this)} className="client__select">{this.props.system.selectedClient !== null ? this.props.system.selectedClient.name : ''}<span className={this.props.system.clientsExpanded ? 'expanded' : ''}>&lsaquo;</span></button>
                        <div className={`client__list ${this.props.system.clientsExpanded ? 'expanded' : ''}`}>
                            <input type="text" onChange={this.props.filterClients} placeholder="Client search..." />

                            <ul>
                                {this.props.accounts.accountsFiltered.map(account =>
                                    <li onClick={() => this.props.selectClient(account, this.props)} key={`acc_${account.id}`}>
                                        {account.name}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                : null}

                <button className="sign__out" onClick={() => this.props.logout()}>Sign Out</button>
            </div>
        )
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
